import { RequestResponse } from "../../DTO/requestResponse";
import { Request } from "../../CONSTANTS/request";
import { MICROSERVICIOS, TOPDESK_CONFIG } from "../../CONSTANTS/constants";




export default class Topdesk {

    static async AgenciasALL(): Promise<RequestResponse> {

        let direccion = `branches?query=optionalFields1.boolean1==false`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }


    static async coloresALL(): Promise<RequestResponse> {

        let direccion = `incidents/free_fields/1/searchlists/4`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }


    static async BusquedaSucural(idSucursal: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY}&branch.id==${idSucursal}`;



        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }


    static async BusquedaSucursalFiltrada(idSucursal: any, fecha: any): Promise<RequestResponse> {


        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};caller.branch.id==${idSucursal};creationDate=ge=${fecha}00:00:00Z`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }




    static async BusquedaColorFiltrada(idColor: any, fecha: any): Promise<RequestResponse> {

        fecha = "2023-06-11T";

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.searchlist4.id==${idColor};creationDate=ge=${fecha}00:00:00Z`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    static async BusquedaRangoFecha(Fecha_Inicio: any, Fecha_Final: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};creationDate=ge=${Fecha_Inicio}00:00:00Z;creationDate=le=${Fecha_Final}00:00:00Z`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }


    // PESO 
    static async BusquedaRangoPeso(Peso_Minimo: any, Peso_Maximo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number5=ge=${Peso_Minimo};optionalFields1.number5=le=${Peso_Maximo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    static async BusquedaRangoPesoMaximo(Peso_Minimo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number5=ge=${Peso_Minimo}`;


        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }




    // ANCHO
    static async BusquedaRangoAncho(Ancho_Minimo: any, Ancho_Maximo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number4=ge=${Ancho_Minimo};optionalFields1.number4=le=${Ancho_Maximo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }




    static async BusquedaRangoAnchoMaximo(Ancho_Minimo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number4=ge=${Ancho_Minimo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    // LARGO

    static async BusquedaRangoLargo(Largo_Minimo: any, Largo_Maximo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number3=ge=${Largo_Minimo};optionalFields1.number3=le=${Largo_Maximo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    static async BusquedaRangoLargoMaximo(Largo_Minimo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number3=ge=${Largo_Minimo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }




    // ALTO

    static async BusquedaRangoAlto(Alto_Minimo: any, Alto_Maximo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number2=ge=${Alto_Minimo};optionalFields1.number2=le=${Alto_Maximo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    static async BusquedaRangoAltoMaximo(Alto_Minimo: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY};optionalFields1.number2=ge=${Alto_Minimo}`;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    static async BusquedaDinamica(texto: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY}` + texto;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }



    // Creacion Ticket Bulto Ciego

    static async CreacionTicketBultoCiego(texto: any): Promise<RequestResponse> {

        let direccion = `incidents?pageSize=${TOPDESK_CONFIG.PAGE_SIZE}&query=subcategory.id==${TOPDESK_CONFIG.SUB_CATEGORY}` + texto;

        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }


    // Validacion Existencia otros Tickets


    // ticket de cambio
    static async ValidaCambioTicket(OrdenFlete: any, fecha: any): Promise<RequestResponse> {
        let direccion = `operatorChanges?query=optionalFields2.text1==${OrdenFlete};creationDate>=${fecha}T00:00:00Z`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }


    
    // ticket de incidente
    static async ValidaIncidenteTicket(OrdenFlete: any, fecha: any, tk:any): Promise<RequestResponse> {
        let direccion = `incidents?query=optionalFields1.text2==${OrdenFlete};creationDate>=${fecha}T00:00:00Z;number!=${tk}`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }

    static async TraeTrickeResuleto(ticket: any): Promise<RequestResponse> {
        let direccion = `operatorChanges/${ticket}`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdesk(direccion);
    }

    // Current User
    static async ObtieneUsuario(): Promise<RequestResponse> {
        let direccion = `persons/current`;
        return new Request(MICROSERVICIOS.TOPDESK).getTopdeskValidacion(direccion);
    }
}