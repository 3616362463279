import moment from "moment";
import { TIPO_TK_PROCESADO } from "../../../API/CONSTANTS/constants";
import Tracking from "../../../API/MICROSERVICES/tracking/tracking";
import { Limpieza } from "../../../SHARED/limpiador";
import { InvierteFecha } from "../../../SHARED/Formatos";

export async function test(mensaje: string) {
    let texto: string = "Imprimo un mensaje " + mensaje;

    return texto;
}

export async function ActualizaColor(image: any) {

    let TK_01 = TIPO_TK_PROCESADO.TIPO_TK_01_REDESTINAR;
    let TK_02 = TIPO_TK_PROCESADO.TIPO_TK_02_DEVOLVER;
    let TK_03 = TIPO_TK_PROCESADO.TIPO_TK_03_SACAR_A_REPARTO;
    let TK_04 = TIPO_TK_PROCESADO.TIPO_TK_04_CAMBIAR_TIPO_ENTREGA;
    // let TK_05 = TIPO_TK_PROCESADO.TIPO_TK_05;

    let clase = image.categoria;
    let color = "";

    if (clase == "null") {

        color = "rgb(231, 255, 233)";

    } else if (clase == TK_01) {
        color = "Lavender";
    } else if (clase == TK_02) {
        color = "PaleGreen";
    } else if (clase == TK_03) {
        color = "LightSteelBlue";

    } else if (clase == TK_04) {
        color = "Bisque";
    } else {

        color = "LemonChiffon";
    }

    return color;
}

export async function CalcularTipoTicket(itemExt: any) {

    let TipoTicket: string = "No hay Clasificación Asociada";
    let MiniTicket: string = "MINI TICKET";
    let BigTicket: string = "BIG TICKET";
    let GigaTicket: string = "GIGA TICKET";

    let alto = itemExt.alto;
    let largo = itemExt.largo;
    let ancho = itemExt.ancho;
    let peso = itemExt.peso;



    let ArrayTickets: any[] = [];

    let TicketAlto = "";
    let TicketLargo = "";
    let TicketAncho = "";
    let TicketPeso = "";

    if (alto >= 0 && alto <= 50) {
        TicketAlto = MiniTicket;
    } else if (alto >= 51 && alto <= 100) {
        TicketAlto = BigTicket;
    } else {
        TicketAlto = GigaTicket;
    }
    ArrayTickets.push(TicketAlto);


    if (largo >= 0 && largo <= 50) {
        TicketLargo = MiniTicket;
    } else if (largo >= 51 && largo <= 100) {
        TicketLargo = BigTicket;
    } else {
        TicketLargo = GigaTicket;
    }
    ArrayTickets.push(TicketLargo);


    if (ancho >= 0 && ancho <= 50) {
        TicketAncho = MiniTicket;
    } else if (ancho >= 51 && ancho <= 100) {
        TicketAncho = BigTicket;
    } else {
        TicketAncho = GigaTicket;
    }
    ArrayTickets.push(TicketAncho);


    if (peso >= 0 && peso <= 50) {
        TicketPeso = MiniTicket;
    } else if (peso >= 51 && peso <= 100) {
        TicketPeso = BigTicket;
    } else {
        TicketPeso = GigaTicket;
    }

    ArrayTickets.push(TicketPeso);




    let Limpio = await Limpieza(ArrayTickets);



    let largoArray = Limpio.length;


    if (largoArray == 1) {
        TipoTicket = "" + Limpio[0];
    } else if (largoArray == 2) {
        TipoTicket = "" + Limpio[1];
    } else if (largoArray == 3) {
        TipoTicket = "" + Limpio[3];
    } else {

    }


    return TipoTicket;

}

export async function cargaImagenes(itemImg: any) {




    let imagenes = itemImg;
    let URLS: any[] = []

    if (imagenes != undefined) {

        if (imagenes != "") {




            let array = imagenes.split(`\n`);




            array.forEach((item: any, index: number) => {






                if (item.includes("Virtual")) {


                } else {

                    if (item != "") {
                        if (index > 0) {
                            URLS.push(item);
                        }

                    }

                }


            });



        }
    }
    return URLS;
}

export async function LimpiaResultados(datos: any[]) {

    let resultado: any[] = []

    datos.forEach((item) => {

        let ordenflete = item.optionalFields1.text2;

        let largo = ordenflete.length

        if (largo > 0) {
            resultado.push(item);
        }
    });

    return resultado
}



export async function CalculoFechaEnvioRezago(ordenFlete: any) {

    console.log("CalculoFechaEnvioRezago");

    // ordenFlete = "959292316";

    let orden = {
        of: ordenFlete
    }


    try{


        let FechaCreaOF_FULL: string = await Tracking.detalle(orden).then((resp) => {
            return resp.data.ordenFlete.fechaEmision;
        });
    
        let respuesta;
    
        if (FechaCreaOF_FULL != null) {
    
            let ArrayFechaCreaOF: any[] = FechaCreaOF_FULL.split("T");
    
            let fechaCreaOri: string = ArrayFechaCreaOF[0];
    
            const date = moment(fechaCreaOri); // Fecha inicial
            const newDate = date.add(20, 'days').format('YYYY-MM-DD');
    
            let FechaCreacion = InvierteFecha(fechaCreaOri);
    
            let FechaFinal = InvierteFecha(newDate);
    
            respuesta = {
    
                creacion: FechaCreacion,
                rezago: FechaFinal
    
            }
    
        } else {
    
            respuesta = {
    
                creacion: "Problemas con OF",
                rezago: "Problemas con OF"
    
            }
    
        }
    
    
        return respuesta;


    }catch(e){

        let respuesta = {
    
            creacion: "Problemas con OF",
            rezago: "Problemas con OF"

        }

        return respuesta;


    }
  

}



export async function cancelar() {
    window.location.reload();
}




