import { RequestResponse } from "../../DTO/requestResponse";
import { Request } from "../../CONSTANTS/request";
import { MICROSERVICIOS } from "../../CONSTANTS/constants";
import { ubicacionFisica } from "../ubicacacionFisica/ubicacionFisica.dto";


export default class UbicFisica {


    // UbicFisica.ObtenerUbicacionFisica
    static async ObtenerUbicacionFisica(datos: any): Promise<RequestResponse> {

        let direccion = "UbicacionFisica";

        return new Request(MICROSERVICIOS.UBICACION_FISICA).postUbicacionFisica(direccion , datos);


    }


}