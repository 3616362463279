import moment from "moment";
import { ESTADOS, VALIDACIONES } from "../../../API/CONSTANTS/constants";
import Topdesk from "../../../API/MICROSERVICES/topdesk/topdesk";
import Rezago from "../../../API/MICROSERVICES/rezago/rezago";
import UbicFisica from "../../../API/MICROSERVICES/ubicacacionFisica/ubicacionFisica";


export let mensa: string;

export async function test(mensaje: string) {
    let texto: string = "Imprimo un mensaje " + mensaje;
  
    return texto;
}



export async function validarOf(resultadoOrdenFlete: any) {

    let final = false;

    if (resultadoOrdenFlete == undefined) {

    } else {
        if (resultadoOrdenFlete.data.ordenFlete.of != null) {
            final = true;
        }
    }

    return final;
}


export async function validarEstado(resultadoOrdenFlete: any) {
    let final = false;

    let estado = resultadoOrdenFlete.data.ordenFlete.codigoEstado;

    let ENTREGADO = ESTADOS.ENTREGADO;
    let ANULADO = ESTADOS.ANULADO;
    let CERRADO_EXCEPCION = ESTADOS.CERRADO_EXCEPCION;
    let REMATADO = ESTADOS.REMATADO;
    let REDESTINADO = ESTADOS.REDESTINADO;
    let DECOMISADO = ESTADOS.DECOMISADO;
    let DEVUELTO = ESTADOS.DEVUELTO;
    let REZAGO_PARCIAL = ESTADOS.REZAGO_PARCIAL;

    if (resultadoOrdenFlete == undefined) {

    } else {

        if (estado != null) {

            if (estado == ENTREGADO) {
            } else if (estado == ANULADO) {
            } else if (estado == CERRADO_EXCEPCION) {
            } else if (estado == REMATADO) {
            } else if (estado == REDESTINADO) {
            } else if (estado == DECOMISADO) {
            } else if (estado == DEVUELTO) {
            } else if (estado == REZAGO_PARCIAL) {
            } else {
                final = true;
            }
        }
    }

    return final;
}


export async function validarTopdesk(resultadoOrdenFlete: any, tk: any) {

  
    let fechaActual = new Date();

    let MesesAntes = Number(VALIDACIONES.TDK_MESES_VALIDACION);

    let fechaAnterior = moment().add('month', -MesesAntes).format('YYYY-MM-DD');

    try {

        let incidenteResponse = await Topdesk.ValidaIncidenteTicket(resultadoOrdenFlete, fechaAnterior, tk).then((resp) => {
           
            return resp;
        })

        let incidente = incidenteResponse.data;

        if(incidenteResponse.status !== 204){
            if (incidente != undefined) {
                if (incidente != null) {
                    if (incidente != "") {
                        mensa = "" + incidente[0].number;
                    }
                }
            }
        }


        let cambioResponse = await Topdesk.ValidaCambioTicket(resultadoOrdenFlete, fechaAnterior).then((resp) => {       
            return resp;
        })

        let cambio = cambioResponse.data.results;

        let validacionIncidente = incidente.length;
        let validacionCambio = cambio.length;

        if (validacionIncidente == 0 && validacionCambio == 0) {

            //  console.log("Puedo pasar");

            const respuesta = {
                mensaje: "",
                validador: true,
            }

            return respuesta;

        } else {
            const respuesta = {
                mensaje: mensa,
                validador: false,
            }
            return respuesta;
        }


    } catch (error) {
    
        const respuesta = {

            mensaje: "" + error,
            validador: false,

        }

        return respuesta;
    }

}


export async function DatosRezagoOrdenFlete(resultadoOrdenFlete: any) {
    let respuesta = await Rezago.DatosRezagoOrdenFlete(resultadoOrdenFlete).then(async (resp) => {
      
        return resp;
    });
  

    return respuesta;
}

export async function DatosRezagoBulto(encacodigobarra: any) {

    let respuesta = await Rezago.DatosRezagoBulto(encacodigobarra).then(async (resp) => {
        console.log("resp", resp);
        return resp;
    });

    return respuesta;
   

}


export async function UbicacionFi(encacodigobarra: any) {

    let respuesta = await UbicFisica.ObtenerUbicacionFisica(encacodigobarra).then(async (resp) => {
      

        return resp.data;
    });

    return respuesta;

}
