
import React, { useEffect, useState } from "react";

import axios from "axios";

import Rezago from '../../API/MICROSERVICES/rezago/rezago';
import Tracking from "../../API/MICROSERVICES/tracking/tracking";
import Topdesk from "../../API/MICROSERVICES/topdesk/topdesk";
import { TDK_DIAS_ANTERIORES } from "../../API/CONSTANTS/constants";
import moment from "moment";



export default function Pruebas(props: any) {


  useEffect(() => {
    algo();

  }, []);


  async function algo() {

    console.log("Ejecutando algo");


    let Texto = "";


    let idSucursal = "e53ad600-8754-4580-b60d-dda663f19cf3";
    let fecha = await CalcularFechaAnterior();

    let idColor = "0bc6b266-f7e1-4fbd-ba70-d5a17b023075";

    let fechaInicio = "2024-11-09T";
    let fechaFinal = "2024-11-22T";

    Texto = Texto + `;caller.branch.id==${idSucursal}`;

    Texto = Texto + `;optionalFields1.searchlist4.id==${idColor}`;


  //  Texto = Texto + `;creationDate=ge=${fecha}00:00:00Z`; // <-- este va siempre al final 

    // Si se suma rango fecha se cambia la cola de final por estos 

    Texto = Texto + `;creationDate=ge=${fechaInicio}00:00:00Z;creationDate=le=${fechaFinal}00:00:00Z`;


    Topdesk.BusquedaDinamica(Texto).then((resp: any) => {

      console.log(resp);

    });










  }


  async function CalcularFechaAnterior() {

    let diasAntes = TDK_DIAS_ANTERIORES;

    let fechaAnterior = moment().add('days', -diasAntes).format('YYYY-MM-DDT');

    return fechaAnterior;

  }



  return (
    <>
      <h1>Hola Mundo</h1>
      <h1>Chao Mundo</h1>

    </>
  )

}
