import axios from "axios";
import { REQUEST_TIMEOUT, AUTENTICATION_TDK_AGENCIAS, AUTENTICACION_CRM, AUTENTICATION_TDK_VALIDACION, CONSULTA_TDK, AUTENTICACION_UBICACION_FISICA } from "./constants";
import { RequestResponse } from "../DTO/requestResponse";


export class Request {

  private endpoint: string;
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  isNetworkError = (err: any) => {
    return !!err.isAxiosError && !err.response;
  };

  // HEADER_CRM
  async _getHeaderCRM() {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    const encoded64 = Buffer.from(`${AUTENTICACION_CRM.user}:${AUTENTICACION_CRM.pass}`, 'ascii').toString('base64');

    header = {
      headers: {
        "Access-Controll-Allow-Origin": "*",
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }


  // HEADER TOPDESK
  async _getHeaderTDK() {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    const encoded64 = Buffer.from(`${AUTENTICATION_TDK_AGENCIAS.user}:${AUTENTICATION_TDK_AGENCIAS.pass}`, 'ascii').toString('base64');

    header = {
      headers: {
        "Access-Controll-Allow-Origin": "*",
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }



  // Header Consulta TopDesk
  async _getHeaderTopDesk() {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    const encoded64 = Buffer.from(`${CONSULTA_TDK.user}:${CONSULTA_TDK.pass}`, 'ascii').toString('base64');

    header = {
      headers: {
        'Access-Controll-Allow-Origin': '*',
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }



   // Header Consulta TopDesk
   async _getHeaderUbicacionFisica() {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    const encoded64 = Buffer.from(`${AUTENTICACION_UBICACION_FISICA.user}:${AUTENTICACION_UBICACION_FISICA.pass}`, 'ascii').toString('base64');

    header = {
      headers: {
        'Access-Controll-Allow-Origin': '*',
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }






  // GET TOPDESK AGENCIAS
  async getTopdesk(url: string): Promise<RequestResponse> {

    let url2: string = this.endpoint + url;

    console.log("urls", url2);

    const encoded64 = Buffer.from(`${AUTENTICATION_TDK_AGENCIAS.user}:${AUTENTICATION_TDK_AGENCIAS.pass}`, 'ascii').toString('base64');

    var axios = require('axios');

    var config = {
      method: 'get',
      url: url2,

      headers: {
        "Access-Controll-Allow-Origin": "*",
        "Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      data: ""
    };



    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {

        const response = await axios(config);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });

      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  // GET TOPDESK
  async getTopdeskValidacion(url: string): Promise<RequestResponse> {

    let url2: string = this.endpoint + url;

    console.log("urls", url2);

    const encoded64 = Buffer.from(`${AUTENTICATION_TDK_VALIDACION.user}:${AUTENTICATION_TDK_VALIDACION.pass}`, 'ascii').toString('base64');

    var axios = require('axios');

    var config = {
      method: 'get',
      url: url2,
      headers: {
        "Access-Controll-Allow-Origin": "*",
        "Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Basic ${encoded64}`,
        ContentType: "application/json",
      },
      data: ""
    };



    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {

        const response = await axios(config);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });

      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }




  // GET
  async get(url: string): Promise<RequestResponse> {
    const header = await this._getHeaderTDK();

    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {


      

        const response = await axios.get(this.endpoint.concat(url), header);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  // POST CONSULTA TOPDESK
  async postTopDesk(url: string, body: any): Promise<RequestResponse> {
    const header = await this._getHeaderTopDesk();
    url = this.endpoint + url;

    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 25000 * REQUEST_TIMEOUT);

      try {

     
        console.log("URL: " + url);


        const response = await axios.post(url, body, header);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "Método Post: No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "Método Post: No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }



    // POST Ubicacion Fisica
    async postUbicacionFisica(url: string, body: any): Promise<RequestResponse> {
      const header = await this._getHeaderUbicacionFisica();
      url = this.endpoint + url;
  
      return new Promise(async (resolve) => {
        setTimeout(() => {
          resolve({
            status: -1,
            description: "Supero el tiempo de espera",
            error: "Tiempo de espera superado",
          });
        }, 25000 * REQUEST_TIMEOUT);
  
        try {
  
       
          console.log("URL: " + url);
  
  
          const response = await axios.post(url, body, header);
          resolve({
            status: response.status,
            description: response.statusText,
            data: response.data,
          });
        } catch (error: any) {
          if (this.isNetworkError(error)) {
            resolve({
              status: -1,
              description:
                "Método Post: No fue posible obtener información, por favor reintente más tarde",
              data: null,
              error:
                "Método Post: No fue posible obtener información, por favor reintente más tarde",
            });
          } else {
            resolve({
              status: error.response.status,
              description: error.response.data.error,
              data: error.response.data,
              error: error.response,
            });
          }
        }
      });
    }
  











  //DELETE
  async delete(url: string): Promise<RequestResponse> {
    const header = await this._getHeaderCRM();
    url = this.endpoint + url;
    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {
        const response = await axios.delete(this.endpoint.concat(url), header);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  //PUT
  async put(url: string, body: any): Promise<RequestResponse> {
    const header = await this._getHeaderCRM();
    url = this.endpoint + url;
    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {
        const response = await axios.put(
          url,
          body,
          header
        );
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  // PATCH
  async patch(url: string, body: any): Promise<RequestResponse> {
    const header = await this._getHeaderCRM();
    url = this.endpoint + url;
    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {
        const response = await axios.patch(
          url,
          body,
          header
        );
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  // POST NORMAL (DETALLE)
  async post(url: string, body: any): Promise<RequestResponse> {
    const header = await this._getHeaderCRM();
    url = this.endpoint + url;



    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);

      try {
        const response = await axios.post(url, body, header);
        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "Método Post: No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "Método Post: No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }




}
