import UbicFisica from "../../../API/MICROSERVICES/ubicacacionFisica/ubicacionFisica";

export function abrirFormulario(url:string){
  window.open(url, "_self");
} 


export async function TraeUbicacionFisica(enca:string, epro:number, fecha:string){

  let Request = {
    encacodigobarra: enca, 
    eprocodigo: epro, 
    fechacompromiso: fecha
  }


  console.log("Request", Request);

  let respuesta =  await UbicFisica.ObtenerUbicacionFisica(Request).then((resp)=>{

      console.log(resp);

      return resp.data;
  });


  return respuesta;

}  





