import React, { useState, useEffect } from "react";
import { inicio, TIPO_TK_PROCESADO, URL_ENVIO_FOMULARIO } from "../../API/CONSTANTS/constants";
import { ESTILOS_COLORES } from "../../API/CONSTANTS/constants";

import { ConfigProvider, Button, Space, Tooltip, Image, Select, DatePicker, Checkbox, Input } from 'antd';
import esES from 'antd/es/locale/es_ES';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import moment from 'moment';
import 'moment/locale/es';

import logoSTK from "../../ASSETS/images/logo-starken.png";

import DataTable, { Alignment } from "react-data-table-component";


import {
    SearchOutlined, FormOutlined, CloseCircleOutlined,
    CheckCircleFilled, RightSquareFilled, EditFilled, ProfileFilled, RocketOutlined,
    QuestionCircleOutlined, CheckCircleOutlined, PictureOutlined, SyncOutlined,
} from '@ant-design/icons';


import type { CheckboxProps } from 'antd';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons";


import { OverlayTrigger, Modal, Spinner, Alert, Container, Row, Col, Card, Pagination, Form, Stack, InputGroup, ProgressBar } from "react-bootstrap";

import { InvierteFecha, HoraCorrecta, CaracteresEspeciales, OrdenaFechaServer, formatoFechaQuery } from "../../SHARED/Formatos";


import { ActualizaColor, CalcularTipoTicket, CalculoFechaEnvioRezago, cancelar, cargaImagenes, LimpiaResultados } from "./Services/AccionesBC";

import { TDK_DIAS_ANTERIORES } from "../../API/CONSTANTS/constants";

import AlertaCentral from "../../SHARED/CentralAlertas";

//DTOS
import { ModalInfo } from "./Entities/modalInfo.DTO";

import Menu from "../../CONTAINERS/menu/menu";
import NoImage from "../../ASSETS/images/foto sin  foto.jpg";

import Topdesk from "../../API/MICROSERVICES/topdesk/topdesk";
import { DelaySeg, DelayMidSeg } from "../../SHARED/temporizadores";
import { Limpieza } from "../../SHARED/limpiador";
import tracking from "../../API/MICROSERVICES/tracking/tracking";

import { consultaTrackingDetalleDto } from "../../API/MICROSERVICES/tracking/consultaTrackingDetalle.dto";
import { mensa, validarEstado, validarOf, validarTopdesk } from "./Services/ValidadorBC";
import { traeBigTicket, traeGigaTicket, traeMiniTicket } from "../../SHARED/CalculoTipoTicket";
import Rezago from "../../API/MICROSERVICES/rezago/rezago";
import Tracking from "../../API/MICROSERVICES/tracking/tracking";
import { abrirFormulario, TraeUbicacionFisica } from "./Services/OperadoresBC";
import { RezagoBulto } from "../../API/MICROSERVICES/rezago/rezagoBulto.dto";


const { RangePicker } = DatePicker;

const images: any[] = [];

type FormElement = React.FormEvent<HTMLFormElement>;

export default function Busqueda_BC(props: any) {



    moment.locale('es');

    let random = Math.random() * Math.random() + Math.random() * Math.random();


    const [numeroAPI, setnumeroAPI] = useState(7);
    const [nomColor, setnomColor] = useState("primary")

    const TK_01 = TIPO_TK_PROCESADO.TIPO_TK_01_REDESTINAR;
    const TK_02 = TIPO_TK_PROCESADO.TIPO_TK_02_DEVOLVER;
    const TK_03 = TIPO_TK_PROCESADO.TIPO_TK_03_SACAR_A_REPARTO;
    const TK_04 = TIPO_TK_PROCESADO.TIPO_TK_04_CAMBIAR_TIPO_ENTREGA;

    const [modalInfoDto, setModalInfoDto] = useState({} as ModalInfo);

    const [modalInfoRezagoDto, setModalRezagoInfoDto] = useState({} as ModalInfo);

    const [OptionAgencias, setOptionAgencias] = useState([] as any[]);
    const [OptionColores, setOptionColores] = useState([] as any[]);

    const [OrdenFleteRezago, setOrdeFleteRezago] = useState(String);
    const [OrdenFleteOriginal, setOrdeFleteOriginal] = useState(String);
    const [DiasRezago, setDiasRezago] = useState(String);
    const [MotivoRezago, setMotivoRezago] = useState(String);
    const [LargoMotivo, setLargoMotivo] = useState(String);

    const [OrdenFleteRezago2, setOrdenFleteRezago2] = useState(String);
    const [OrdenFleteOriginal2, setOrdenFleteOriginal2] = useState(String);
    const [DiasRezago2, setDiasRezago2] = useState(String);
    const [MotivoRezago2, setMotivoRezago2] = useState(String);
    const [LargoMotivo2, setLargoMotivo2] = useState(String);

    const [OFOriginal, setOFOriginal] = useState(false);
    const [OFTieneRTezago, setOFTieneRTezago] = useState(false);
    const [AccionRezago, setAccionRezago] = useState(false);

    const [DisableBotonEnvio, setDisableBotonEnvio] = useState(false);


    const [estadoBotonBultoCiego, setEstadoBotonBultoCiego] = useState(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => { setShowU(false); setShow1(false); };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => { setShowU(false); setShow2(false); };

    const [enableViewRezago, setEnableViewRezago] = useState(false); // FALSE OCULTA LA SECCION DE REZAGO
    const [enableViewGestionar, setEnableViewGestionar] = useState(false);

    const Color_Main_BackGround = ESTILOS_COLORES.MAIN_BACKGROUND;
    const Color_Sucursales = ESTILOS_COLORES.SUCURSALES;
    const Color_Colores = ESTILOS_COLORES.COLORES;
    const Color_Tickets = ESTILOS_COLORES.TICKETS;
    const Color_Fechas = ESTILOS_COLORES.FECHAS;
    const Color_PalabrasClaves = ESTILOS_COLORES.PALABRAS_CLAVES;
    const Color_BackGround_Limpieza = ESTILOS_COLORES.BACKGROUND_LIMPIEZA;
    const Color_Limpieza = ESTILOS_COLORES.LIMPIEZA;
    const Color_Blanco = ESTILOS_COLORES.BLANCO;
    const Color_Verde = ESTILOS_COLORES.VERDE;
    const Color_Rojo = ESTILOS_COLORES.ROJO;
    const Color_Ticket_SinEstado = ESTILOS_COLORES.TICKET_SIN_ESTADO;
    const Color_Ticket_Original = ESTILOS_COLORES.TICKET_ORIGINAL;
    const Color_Ticket_Redestinado = ESTILOS_COLORES.TICKET_REDESTINADO;
    const Color_Ticket_Devuelto = ESTILOS_COLORES.TICKET_DEVUELTO;
    const Color_Ticket_Rezago = ESTILOS_COLORES.TICKET_REZAGO;
    const Color_Ticket_Sobrante = "LemonChiffon";
    const Color_Encabezado = ESTILOS_COLORES.ENCABEZADO;
    const Color_Footer = ESTILOS_COLORES.FOOTER;
    const Color_Gris = ESTILOS_COLORES.GRIS;
    const Color_Negro = ESTILOS_COLORES.NEGRO;
    const Color_Verde_Agua = ESTILOS_COLORES.VERDE_AGUA;
    const Color_Amarillo_Canario = ESTILOS_COLORES.AMARILLO_CANARIO;


    const [idSucursal, setIdSucursal] = useState("");
    const [nombreSucursal, setNombreSucursal] = useState("");
    const [idColor, setIdColor] = useState("");
    const [nombreColors, setNombreColors] = useState("");

    const [nombreTipoTk, setnombreTipoTk] = useState("");
    const [nombreFecha, setNombreFecha] = useState("");
    const [nombrePalabra, setNombrePalabra] = useState("");

    const [selectorAccionInfo, setselectorAccionInfo] = useState("null");

    const [recuperacionTicket, setrecuperacionTicket] = useState("");
    const [recuperacionAgente, setrecuperacionAgente] = useState("");
    const [recuperacionTipo, setrecuperacionTipo] = useState("");

    const [idFechaRango, setIdFechaRango] = useState([] as any[]);
    const [FechaInicio, setFechaInicio] = useState("");
    const [FechaFinal, setFechaFinal] = useState("");

    const [idTipoTicket, setIdTipoTicket] = useState("MINI TICKET");


    const [color, setColor] = useState(Color_Verde);
    const [colorCardHeader, setColorCardHeader] = useState("linear-gradient(rgb(233, 255, 235), rgb(242, 248, 244)");
    const [eleccion, setEleccion] = useState("");
    const [mensaje, setMensaje] = useState("La paciencia es la clave del triunfo.");
    const [totalTK, setTotalTK] = useState(0);


    const [oFOrifinal, setOFOrifinal] = useState("");
    const [EncaCodigoBarra, setEncaCodigoBarra] = useState("");
    const [FechaComprmiso, setFechaComprmiso] = useState("");
    const [EstadoProcesoCodigo, setEstadoProcesoCodigo] = useState("");


    const [checkedColor, setCheckedColor] = useState(false);
    const [checkedSucursal, setCheckedSucursal] = useState(false);
    const [checkedTipoTicket, setCheckedTipoTicket] = useState(false);
    const [checkedRangoFecha, setCheckedRangoFecha] = useState(false);
    const [checkedPalabraClave, setCheckedPalabraClave] = useState(false);

    const [cantidadPorPagina, setCantidadPorPagina] = useState(16);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(images.length / cantidadPorPagina);
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };


    const [CargaBarra, setCargaBarra] = useState(0);

    const startIndex = (currentPage - 1) * cantidadPorPagina;
    const endIndex = startIndex + cantidadPorPagina;
    const currentImages = images.slice(startIndex, endIndex);


    const [titulo, setTitulo] = useState("");
    const [estado,] = useState(false)
    const [busquedaCiega, setBusquedaCiega] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setShow(false); };

    const [permitirBuscar, setPermitirBuscar] = useState(false);

    const [frame1, setframe1] = useState(true);
    const [frame2, setframe2] = useState(true);
    const [frame3, setframe3] = useState(true);
    const [frame4, setframe4] = useState(true);
    const [frame5, setframe5] = useState(true);

    const dateFormat = 'DD/MM/YYYY';

    async function soloFrame1() {

        const stateforzaro = async () => {

            await setframe1(false);
            await setframe2(true);
            await setframe3(true);
            await setframe4(true);
            await setframe5(true);
        }
        await stateforzaro();
    }

    async function soloFrame2() {

        const stateforzaro = async () => {

            await setframe1(true);
            await setframe2(false);
            await setframe3(true);
            await setframe4(true);
            await setframe5(true);
        }
        await stateforzaro();
    }

    async function soloFrame3() {

        const stateforzaro = async () => {

            await setframe1(true);
            await setframe2(true);
            await setframe3(false);
            await setframe4(true);
            await setframe5(true);
        }
        await stateforzaro();
    }

    async function soloFrame4() {

        const stateforzaro = async () => {

            await setframe1(true);
            await setframe2(true);
            await setframe3(true);
            await setframe4(false);
            await setframe5(true);
        }
        await stateforzaro();
    }

    async function soloFrame5() {

        const stateforzaro = async () => {

            await setframe1(true);
            await setframe2(true);
            await setframe3(true);
            await setframe4(true);
            await setframe5(false);
        }
        await stateforzaro();
    }

    const onChangeDP = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {

        let arregloFecha: any[] = [dateString[0], dateString[1]];

        nombreFechas(dateString);
        forzarFecha(arregloFecha);
        setPermitirBuscar(true);
    };

    const onChange: CheckboxProps['onChange'] = (e) => {

        let valor = e.target.checked;

        if (valor) {
            setEstadoBotonBultoCiego(false);

        } else {
            setEstadoBotonBultoCiego(true);

        }
    };

    const nombreFechas = (fecha: any) => {

        const StateForzadoInicio = () => {
            return setNombreFecha("" + fecha[0] + " -> " + fecha[1]);
        }

        StateForzadoInicio();

    }

    const forzarFecha = (arreglo: any[]) => {

        const StateForzadoInicio = () => {
            return setIdFechaRango(arreglo);;
        }

        StateForzadoInicio();
    }

    const forzarTotalTK = (total: any) => {

        const StateForzado = () => {
            return setTotalTK(total);;
        }

        StateForzado();

    }

    const { history } = props;    // para los cambios de pagina

    // function validacion() {
    //     if (inicio.permitido === false) {
    //         PaginaError();
    //     }
    // }

    // function PaginaError() {
    //     history.push("/InValidDomain");
    // }

    // useEffect(() => {
    //     validacion();
    //     // eslint-disable-next-line
    // }, [])

    //=====================================================================================

    const [mostrar, setMostrar] = useState(false);

    const [showU, setShowU] = useState(false);

    const onChangeAgencia = (value: string) => {

        let id: string = value;

        console.log("id sucursal", value);

        const StateForzado = () => {
            return setIdSucursal(id);
        }

        nombreAgencia(id);
        StateForzado();
        setPermitirBuscar(true);

    };

    const nombreAgencia = async (id: string) => {

        let nombreAgencia: any[] = await OptionAgencias.filter(item => (item.value == id));

        const StateForzado = () => {
            setNombreSucursal(nombreAgencia[0].label);
        }

        StateForzado();

    }

    const nombreColor = async (id: string) => {

        let nombreColor: any[] = await OptionColores.filter(item => (item.value == id));

        const StateForzado = () => {
            setNombreColors(nombreColor[0].label);
        }

        StateForzado();

    }

    const onSearchAgencia = (value: string) => {

    };

    const onChangeColor = (value: string) => {

        let id: string = value;
        const StateForzado = () => {
            return setIdColor(id);
        }

        nombreColor(id);
        StateForzado();
        setPermitirBuscar(true);
    };

    const onSearchColor = (value: string) => {

    };

    const handleTicket = (value: string | string[]) => {


        let id: string = "" + value;

        const StateForzado = () => {
            return setIdTipoTicket(id);
        }

        NobreTipoTicket(value);
        StateForzado();
    };

    const NobreTipoTicket = async (nombre: any) => {

        let nombreTipoTicket = nombre;

        const StateForzado = async () => {
            return setnombreTipoTk(nombreTipoTicket);
        }

        await StateForzado();
        setPermitirBuscar(true);

    }

    const handleSelectColor = (e: CheckboxChangeEvent) => {


        let color = e.target.checked;

        const forzado = (forzado: any) => {
            return setCheckedColor(forzado);
        }

        forzado(color);

        return new Promise((resolve, reject) => {
            resolve(setCheckedColor(e.target.checked));
        });
    };

    const handleSelectSucursal = (e: CheckboxChangeEvent) => {


        let color = e.target.checked;

        const forzado = (forzado: any) => {
            return setCheckedSucursal(forzado);
        }

        forzado(color);

        return new Promise((resolve, reject) => {
            resolve(setCheckedSucursal(e.target.checked));
        });
    };

    const handleSelectTipoTicket = (e: CheckboxChangeEvent) => {


        let tipoticket = e.target.checked;

        const forzado = (forzado: any) => {
            return setCheckedTipoTicket(forzado);
        }

        forzado(tipoticket);

        return new Promise((resolve, reject) => {
            resolve(setCheckedTipoTicket(e.target.checked));
        });
    };

    const handleSelectRangoFecha = (e: CheckboxChangeEvent) => {


        let rango = e.target.checked;

        const forzado = (forzado: any) => {
            return setCheckedRangoFecha(rango);
        }

        forzado(color);

        return new Promise((resolve, reject) => {
            resolve(setCheckedRangoFecha(e.target.checked));
        });
    };

    const handleSelectPalabraClave = (e: CheckboxChangeEvent) => {


        let palabra = e.target.checked;

        const forzado = (forzado: any) => {
            return setCheckedPalabraClave(forzado);
        }

        forzado(palabra);

        return new Promise((resolve, reject) => {
            resolve(setCheckedPalabraClave(e.target.checked));
        });

    };

    useEffect(() => { }, [checkedColor, checkedSucursal, checkedTipoTicket, checkedRangoFecha]);

    //================================================================================================================

    async function construirGaleria(galeria: any[]) {

        setShowU(true);

        let sucursales: any[] = galeria;

        if (sucursales !== undefined) {

            let otroLargo = sucursales.length;

            if (otroLargo > 0) {

                await forzarTotalTK(sucursales.length);

                sucursales.forEach(async (item) => {

                    let img: any[] = [];

                    if (item.optionalFields2.memo1 != undefined) {
                        if (item.optionalFields2.memo1 != null) {

                            let imagen: string = item.optionalFields2.memo1;
                            let imagenes: any[] = []

                            imagenes = await cargaImagenes(imagen);

                            if (imagenes.length > 0) {

                                img = imagenes;
                            }
                        }
                    }

                    let colors;

                    try {
                        if (item.optionalFields1.searchlist4.name != undefined) {

                            if (item.optionalFields1.searchlist4.name != null) {

                                colors = item.optionalFields1.searchlist4.name;
                            } else {
                                colors = "Sin Color";
                            }
                        } else {
                            colors = "Sin Color";
                        }


                    } catch (e) {

                        colors = "Sin Color";

                    }

                    let categoria = item.optionalFields1.searchlist5 != null ? item.optionalFields1.searchlist5.id : "null";

                    let suc = item.caller.branch.name;



                    let sucursal: ModalInfo = {
                        "ticket": item.number,
                        "imagenes": img,
                        "obs": item.request,
                        "hora": item.creationDate,
                        "fecha": item.creationDate,
                        "creador": item.caller.dynamicName,
                        "ordenFlete": item.optionalFields1.text2,
                        "color": colors,
                        "alto": item.optionalFields1.number2,
                        "largo": item.optionalFields1.number3,
                        "ancho": item.optionalFields1.number4,
                        "peso": item.optionalFields1.number5,
                        "categoria": categoria,
                        "ticketRecuperado": item.externalNumber,
                        "ticketRecuperadoPOR": "",
                        "tipoTicket": "",
                        "largoObs": "",
                        "agencia": suc,
                        "fechaEnvioRezago": "",
                        "fechaCreacionOf": "",
                    }

                    images.push(sucursal);
                })

            }

            setTimeout(() => {
                setBusquedaCiega(true);
                setShowU(false);
            }, 2);

        }

    }

    //================================================================================================================

    async function CalcularFechaAnterior() {

        let diasAntes = TDK_DIAS_ANTERIORES;

        let fechaAnterior = moment().add('days', -diasAntes).format('YYYY-MM-DDT');

        return fechaAnterior;

    }

    async function CalcularSieteMesesAntes() {

        let MesesAntes = 7;

        let fechaAnterior = moment().add('month', -MesesAntes).format('YYYY-MM-DD');

        return fechaAnterior;

    }


    const handleSubmit = (e: FormElement) => { // inicio handleSubmit
        e.preventDefault();
        setShowU(true);
        AccionIngresarOfOriginal(modalInfoDto);
    }; // final handleSubmit

    // primero esta funcion 01 ========================================================================================
    async function cargaOptions() {

        setShowU(true);
        cambiaMensaje();

        await Topdesk.AgenciasALL().then(async (resp: any) => {

            let ArrayAgencias: any[] = [];

            let data = resp.data;

            try {

                data.map((age: any) => {

                    let agencia = {
                        label: age.name,
                        value: age.id,
                    }

                    ArrayAgencias.push(agencia);

                    return null;
                });

            } catch (e) { }

            setTimeout(() => {
                setOptionAgencias(ArrayAgencias);
            }, 1);

        });

        await Topdesk.coloresALL().then(async (resp: any) => {

            let ArrayColores: any[] = [];

            let data = resp.data;

            try {

                data.map((age: any) => {

                    let color = {
                        label: age.name,
                        value: age.id,
                    }

                    ArrayColores.push(color);

                    return null;
                });


                console.log(ArrayColores);

            } catch (e) { }

            setTimeout(() => {
                setOptionColores(ArrayColores);
            }, 1);

        });

        setShowU(false);

    }

    useEffect(() => {

        cargaOptions();

    }, []);
    // =================================================================================================================
    async function cambiaMensaje() {

        let mensaje1 = "¡Estamos preparando todo para ti!";
        let mensaje2 = "La paciencia es una virtud.";
        let mensaje3 = "Estamos recogiendo píxeles \n para formar la imagen perfecta.";
        let mensaje4 = "Por favor,\n espera un momento.";
        let mensaje5 = "Gracias por ser virtuoso\n mientras preparamos todo.";
        let mensaje6 = "¡Casi listo!";

        await DelaySeg(2);

        setMensaje(mensaje1);

        await DelaySeg(2);

        setMensaje(mensaje2);

        await DelaySeg(2);

        setMensaje(mensaje3);

        await DelaySeg(2);

        setMensaje(mensaje4);

        await DelaySeg(2);

        setMensaje(mensaje5);

        await DelaySeg(2);

        setMensaje(mensaje6);

        await DelaySeg(2);

        setMensaje("Estamos trabajando juntos hacia la grandeza.");

        setShowU(false);
    }

    useEffect(() => { }, [enableViewRezago])

    const [arrayEncargos, setArrayEncargos] = useState([] as any);

    async function cargaRezago(encacodigobarra: string) {



        let enca: string = encacodigobarra;
        // enca = "92008562560093706098184001";

        let encacodigo: RezagoBulto = { encacodigobarra: enca };

        let MensajeRezago: string = "Encargo sin Rezago";

        try {

            let rezago = await Rezago.DatosRezagoBulto(encacodigo).then(async (resp) => {
                return resp.data;
            });


            if (rezago.codigo_ORDEN_FLETE_REZAGO != "-1") {

                setOrdeFleteRezago(rezago.codigo_ORDEN_FLETE_REZAGO);
                setDiasRezago(rezago.dias_REZAGO);
                setMotivoRezago(rezago.motivo_REZAGO.trim());
                setOrdeFleteOriginal(rezago.codigo_ORDEN_FLETE_ORIGINAL);


                let largo: string = "" + rezago.motivo_REZAGO.trim().length;
                setLargoMotivo(largo);


                let motivo: string = "" + rezago.motivo_REZAGO;


                if (selectorAccionInfo == "null") {
                    if (motivo != null) {
                        if (motivo != "No ingreso Orden de Flete") {
                            forzarEstadoRezago();
                            forzarOFTieneRTezago();
                        } else { }
                    } else { }
                } else { }
            } else {

                setOrdeFleteRezago("");
                setDiasRezago("");
                setMotivoRezago("");
                setOrdeFleteOriginal("");
                setLargoMotivo("0");

                const forzar = async () => {
                    await setEnableViewRezago(false);
                    await setOFTieneRTezago(false);
                };

                await forzar();


            }

        } catch (e) {

            setOrdeFleteRezago(MensajeRezago);
            setDiasRezago(MensajeRezago);
            setMotivoRezago(MensajeRezago);
            setOrdeFleteOriginal(MensajeRezago);
            setLargoMotivo("0");

            const forzar = async () => {
                await setEnableViewRezago(false);
                await setOFTieneRTezago(false);
            };

            await forzar();
        }

    }

    async function cargaModalRezago(encacodigobarra: string, modalInfoDto: any) {

        let ejecutar: number = 0;

        let enca = encacodigobarra;
        //  92008562560093706098184001

        //92003101000310959305046001
        // enca = "92003101000310959305046001";

        let encacodigo = { encacodigobarra: enca };

        if (encacodigobarra != null) {

            try {

                let rezago = await Rezago.DatosRezagoBulto(encacodigo).then(async (resp) => {
                    return resp.data;
                });




                setOrdenFleteRezago2(rezago.codigo_ORDEN_FLETE_REZAGO);
                setDiasRezago2(rezago.dias_REZAGO);
                setMotivoRezago2(rezago.motivo_REZAGO.trim());
                setOrdenFleteOriginal2(rezago.codigo_ORDEN_FLETE_ORIGINAL);

                let largo: string = "" + rezago.motivo_REZAGO.trim().length;

                setLargoMotivo2(largo);

                if (rezago != null && rezago.motivo_REZAGO != "-1") {
                    ejecutar = 1;
                    setShow2(true);
                    forzarOFTieneRTezago();

                } else {
                    setLargoMotivo2("0");
                    setMotivoRezago2("");
                    setDiasRezago2("");
                    setOrdenFleteOriginal2("");
                    setOrdenFleteRezago2("");

                    const forzar = async () => {
                        await setOFTieneRTezago(false);
                    };

                    await forzar();

                }

                return ejecutar;

            } catch (e) { return ejecutar; }

        }
    }

    const forzarEstadoRezago = () => {

        const StateForzadoInicio = () => {
            return setEnableViewRezago(true);
        }

        StateForzadoInicio();

    }

    async function cargaEncargos(OrdenFlete: any) {

        let of = OrdenFlete;
        //  of = 86702107;

        let of2 = { of: of };

        let Encars: any[] = [];


        if (OrdenFlete != "No hay Of Relacionada") {

            let encargos = await Tracking.detalle(of2).then(async (resp) => {

                let DATA = resp.data;

                let fechaCom = DATA.ordenFlete.fechaCompromiso;

                if (fechaCom != null) {
                    let ArrayFecha = DATA.ordenFlete.fechaCompromiso.split("T");
                    setFechaComprmiso(ArrayFecha[0]);

                } else if (fechaCom != "") {
                    setFechaComprmiso("Sin Fecha Compromiso");
                } else {
                    setFechaComprmiso("Sin Fecha Compromiso");
                }


                setEstadoProcesoCodigo(resp.data.ordenFlete.codigoEstado);

                return resp.data.encargos;
            });

            if (encargos.length != 0) {

                let en = encargos[0];

                setEncaCodigoBarra(en.encargoCodigoBarra);

                let enc: {} = {

                    encargo: en.encargoCodigoBarra,
                    button: <Button className="mt-1 md-1" type="primary" style={{ color: Color_Blanco, backgroundColor: Color_Verde }} onClick={() => { DelaySeg(0.1); setShow1(true); }}> Recuperar Bulto Ciego </Button>
                }

                Encars.push(enc);

                setArrayEncargos(Encars);

                await cargaRezago(en.encargoCodigoBarra);
            }
        }
    };

    const columns = [
        {
            name: "Encargo",
            selector: (row: any) => row.encargo
        },
        {
            name: "Acción",
            selector: (row: any) => row.button
        }

    ];

    // funcion que ordena la carga de datos
    async function ordenDeCarga(modalInfoDto: ModalInfo) {


        await CargaModalInfo(modalInfoDto);
    }

    async function CargaModalInfo(itemExt: ModalInfo) {

        let datos = itemExt.obs;
        let arrayObs = datos.split("Observaciones");
        let obs = arrayObs[1];

        let obsLimpia = CaracteresEspeciales(obs);

        modalInfoDto.obs = obsLimpia.toUpperCase();

        let hora = HoraCorrecta(itemExt.hora);

        modalInfoDto.hora = hora;

        let fecha = InvierteFecha(itemExt.fecha);

        modalInfoDto.fecha = fecha;

        modalInfoDto.creador = itemExt.creador;

        modalInfoDto.ticket = itemExt.ticket;

        modalInfoDto.ordenFlete = (itemExt.ordenFlete != "") ? itemExt.ordenFlete : "No hay Of Relacionada";


        console.log("itemExt.ordenFlete", itemExt.ordenFlete);

        let Fechas = await CalculoFechaEnvioRezago(modalInfoDto.ordenFlete);




        modalInfoDto.fechaEnvioRezago = (itemExt.ordenFlete != "") ? Fechas.rezago : "No se Puede Calcular Fecha";

        modalInfoDto.fechaCreacionOf = (itemExt.ordenFlete != "") ? Fechas.creacion : "No se Puede Calcular Fecha";

        modalInfoDto.color = (itemExt.color != "") ? itemExt.color : "No hay Clasificacion de Color";

        modalInfoDto.tipoTicket = await CalcularTipoTicket(itemExt);

        let largo = itemExt.imagenes.length;

        let array: any[] = []

        if (largo > 0) {

            try {

                itemExt.imagenes.map((item: any, index: any) => {

                    array.push(<Image width={150} src={item} fallback={NoImage} alt={'Image' + index + random} key={'Image' + index + random} />);

                    return null;
                });
            } catch (e) { }

        } else {
            array.push(<Image width={150} src={NoImage} fallback={NoImage} alt={'Image' + random} key={'Image' + random} />);
        }

        modalInfoDto.imagenes = array;

        modalInfoDto.largoObs = (modalInfoDto.obs.length != 0) ? "" + modalInfoDto.obs.length : "0";

        modalInfoDto.ticketRecuperadoPOR = "";
        modalInfoDto.ticketRecuperado = "";

        modalInfoDto.agencia = itemExt.agencia;

        setModalInfoDto({ ...modalInfoDto })


        await cargaEncargos(modalInfoDto.ordenFlete);
        setShow(true);

    }

    async function BusquedaConcentrada(tipo: any) {



        if (checkedSucursal == true
            || checkedColor == true
            || checkedTipoTicket == true
            || checkedRangoFecha == true
            || checkedPalabraClave == true
        ) {


            busquedaOmni();

        } else {
            if (tipo == "sucursal") {

                busquedaSucursal(idSucursal);
                await DelaySeg(1);

            } else if (tipo == "color") {

                busquedaColor(idColor);
                await DelaySeg(1);

            } else if (tipo == "tipoticket") {

                busquedaTipoTicket(idTipoTicket);
                await DelaySeg(1);

            } else if (tipo == "fecha") {

                busquedaFecha();
                await DelaySeg(1);

            } else {
                busquedaPalabra("palabra");
            }
        }
    }

    async function busquedaSucursal(id: string) {

        setShowU(true);
        cambiaMensaje();

        images.length = 0;

        let fecha = await CalcularFechaAnterior();

        let idprovisorio = "5d07bc09-7793-4c7c-977b-97defc84aec6";

        // id = idprovisorio;

        const sucursales: any[] = await Topdesk.BusquedaSucursalFiltrada(id, fecha).then(async (resp) => {

            if (resp.error) {

                AlertaCentral("ERROR");
                setShowU(false);

            } else {


                let largo = await resp.data;

                if (largo.length > 0) {

                    return resp.data;
                } else {
                    setShowU(false);
                    AlertaCentral("NOBULTOCIEGO");
                }
            }
        });

        // let sucursales_finales: any[] = await LimpiaResultados(sucursales);


        if (checkedSucursal == false) {
            await construirGaleria(sucursales);
        } else {
            return sucursales;
        }


    }

    async function busquedaColor(id: string) {
        setShowU(true);
        cambiaMensaje();


        images.length = 0;

        let fecha = await CalcularFechaAnterior();
        const sucursales: any[] = await Topdesk.BusquedaColorFiltrada(id, fecha).then(async (resp) => {

            if (resp.error) {

                AlertaCentral("ERROR");
                setShowU(false);

            } else {

                let largo = await resp.data;

                if (largo.length > 0) {

                    return resp.data;
                } else {
                    setShowU(false);
                    AlertaCentral("NOBULTOCIEGOCOLOR");
                }
            }


        });



        if (checkedColor == false) {


            await construirGaleria(sucursales);

        } else {

            return sucursales;
        }

    }

    async function busquedaTipoTicket(id?: string) {


        setShowU(true);
        cambiaMensaje();

        images.length = 0;

        let sucursales: any[] | null;

        if (idTipoTicket == "MINI TICKET") {

            sucursales = await traeMiniTicket();

        } else if (idTipoTicket == "BIG TICKET") {

            sucursales = await traeBigTicket();

        } else {

            sucursales = await traeGigaTicket();

        }

        if (checkedTipoTicket == false) {



            if (sucursales != null) {
                if (sucursales.length != 0) {
                    await construirGaleria(sucursales);
                    setShowU(false);

                } else {
                    AlertaCentral("NOBULTOCIEGOMULTI");
                    setShowU(false);

                }
                setShowU(false);
            } else {

                AlertaCentral("NOBULTOCIEGOMULTI");
                setShowU(false);
            }




        } else {

            setShowU(false);

            if (sucursales != null) {

                if (sucursales.length != 0) {

                    return sucursales;
                } else {
                    AlertaCentral("NOBULTOCIEGOMULTI");
                    setShowU(false);

                }

            } else {
                AlertaCentral("NOBULTOCIEGOMULTI");
                setShowU(false);

            }
        }
    }

    async function busquedaFecha() {


        setShowU(true);
        cambiaMensaje();

        images.length = 0; // Limpia Galeria.

        let fechaOrdenada1 = OrdenaFechaServer(idFechaRango[0]);
        let fechaOrdenada2 = OrdenaFechaServer(idFechaRango[1]);


        let Inicio: any = new Date(fechaOrdenada1);
        let Final: any = new Date(fechaOrdenada2);

        let diff: any = Final - Inicio;
        let resultado: number = await diff / (1000 * 60 * 60 * 24);



        let fechaInicio = formatoFechaQuery(fechaOrdenada1);
        let fechaFinal = formatoFechaQuery(fechaOrdenada2);

        setFechaInicio(fechaInicio);
        setFechaFinal(fechaFinal);

        // fechaInicio = "2023-07-04T";
        // fechaFinal = "2023-07-12T";




        let FechaActual = new Date();
        let FechaActualISO = FechaActual.toISOString();
        let ArrayFechaActual = FechaActualISO.split("T");
        let FechaActualFormateada = ArrayFechaActual[0];

        let mesesAnteriores = await CalcularSieteMesesAntes();


        let inicio2: any = new Date(mesesAnteriores);

        let final2: any = new Date(fechaOrdenada1)


        if (final2 > inicio2) {


            if (resultado > 15) {
                setShowU(false);
                AlertaCentral("DIASMAXIMOS")

            } else {
                const sucursales: any[] = await Topdesk.BusquedaRangoFecha(fechaInicio, fechaFinal).then(async (resp) => {

                    if (resp.error) {
                        AlertaCentral("ERROR");
                        setShowU(false);
                    } else {

                        let largo = await resp.data;

                        if (largo.length > 0) {

                            return resp.data;
                        } else {


                            if (checkedRangoFecha == true) {

                                return [];

                            } else {

                                setShowU(false);
                                AlertaCentral("NOTICKETRANGOFECHA");
                            }


                        }
                    }
                });


                if (checkedRangoFecha == false) {

                    await construirGaleria(sucursales);
                } else {
                    return sucursales;
                }

            }
        } else {

            setShowU(false);
            AlertaCentral("RANGOFECHASUPERIOR");

        }

    }

    async function busquedaPalabra(id: string) {


        let vacio: any[] = []

        if (checkedPalabraClave == false) {


            return vacio;


        } else {

            return vacio;
        }


    }

    async function busquedaOmni() {

        let resp: any[] = [];
        let sucursales: any[] | undefined = [];
        let colores: any[] | undefined = [];
        let tickets: any[] | undefined = [];
        let fechas: any[] | undefined = [];
        let palabras: any[] | undefined = [];

        if (checkedSucursal == true) {

            sucursales = await busquedaSucursal(idSucursal);
        }


        if (checkedColor == true) {

            colores = await busquedaColor(idColor);

        }

        if (checkedTipoTicket == true) {

            tickets = await busquedaTipoTicket(idTipoTicket);
        }

        if (checkedRangoFecha == true) {

            fechas = await busquedaFecha();

        }

        if (checkedPalabraClave == true) {

            palabras = await busquedaPalabra("hola");

        }

        let prm1: any[] = resp.concat(sucursales ?? []);
        let prm2: any[] = prm1.concat(colores ?? []);
        let prm3: any[] = prm2.concat(tickets ?? []);
        let prm4: any[] = prm3.concat(fechas ?? []);
        let final: any[] = prm4.concat(palabras ?? []);

        let arraylimpio: any[] = Limpieza(final);

        setShowU(false);
        await construirGaleria(arraylimpio);

    }

    async function busquedaOmniCiente() {

        images.length = 0; // Limpia Galeria.

        let resp: any[] = [];

        let Texto = "";

        if (checkedSucursal == true) {

            Texto = Texto + `;caller.branch.id==${idSucursal}`;

        }


        if (checkedColor == true) {

            Texto = Texto + `;optionalFields1.searchlist4.id==${idColor}`;

        }



        if (checkedRangoFecha == true) {

            let fechaOrdenada1 = OrdenaFechaServer(idFechaRango[0]);
            let fechaOrdenada2 = OrdenaFechaServer(idFechaRango[1]);


            let Inicio: any = new Date(fechaOrdenada1);
            let Final: any = new Date(fechaOrdenada2);

            let diff: any = Final - Inicio;
            let resultado: number = await diff / (1000 * 60 * 60 * 24);



            let fechaInicio = formatoFechaQuery(fechaOrdenada1);
            let fechaFinal = formatoFechaQuery(fechaOrdenada2);

            setFechaInicio(fechaInicio);
            setFechaFinal(fechaFinal);




            Texto = Texto + `;creationDate=ge=${fechaInicio}00:00:00Z;creationDate=le=${fechaFinal}00:00:00Z`;

        } else {

            let fecha = await CalcularFechaAnterior();

            Texto = Texto + `;creationDate=ge=${fecha}00:00:00Z`; // <-- este va siempre al final
        }


        resp = await Topdesk.BusquedaDinamica(Texto).then((resp: any) => {

            return resp.data;

        });


        setShowU(false);

        await construirGaleria(resp);

    }


    async function AccionIngresarOfOriginal(modalInfoDto: ModalInfo) {

        setShowU(true);

        let OF_BULTO_CIEGO: number = Number(modalInfoDto.ordenFlete);
        let OF_INGRESADA: number = Number(oFOrifinal);

        setAccionRezago(true);


        let OF: consultaTrackingDetalleDto = {
            of: OF_INGRESADA
        };


        if (oFOrifinal.length > 0) {
            if (OF_BULTO_CIEGO != OF_INGRESADA) {

                tracking.detalle(OF).then(async (resp: any) => {

                    let error = resp.error;

                    if (error === undefined) {

                        if (await validarOf(resp)) {
                            if (await validarEstado(resp)) {

                                let valtop: boolean = (await validarTopdesk(OF_INGRESADA, modalInfoDto.ticket)).validador;

                                if (valtop == true) {
                                    setShowU(false);
                                    let modal = await cargaModalRezago(resp.data.encargos[0].encargoCodigoBarra, modalInfoDto);

                                    if (modal === 0) {
                                        forzarOFOriginal();
                                        OpenForm(modalInfoDto);

                                    }
                                } else {
                                    setShowU(false);
                                    AlertaCentral("OFCONTICKET", "", "", "" + OF_INGRESADA, (await validarTopdesk(OF_INGRESADA, modalInfoDto.ticket)).mensaje);
                                };
                            } else {
                                setShowU(false);
                                AlertaCentral("OFNOALTERABLE", "", "", "" + OF_INGRESADA);
                            }
                        } else {
                            setShowU(false);
                            AlertaCentral("OFNOEXISTE", "", "", "" + OF_INGRESADA);
                        }
                    } else {
                        setShowU(false);
                        AlertaCentral("OFNOEXISTE", "", "", "" + OF_INGRESADA);
                    }

                });

                setShowU(false);
            } else {
                setShowU(false);
                AlertaCentral("OFBULTOCIEGO", "", "", "" + OF_INGRESADA);
            }
        } else {
            setShowU(false);
            AlertaCentral("DEBEINGRESAROF");
        }
    }

    async function AccionIngresarOfTicket(modalInfoDto: ModalInfo) {


        setShowU(true);


        try {

            if (modalInfoDto.ordenFlete != `No hay Of Relacionada`) {

                let OF_BULTO_CIEGO: number = Number(modalInfoDto.ordenFlete);

                let OF: consultaTrackingDetalleDto = {
                    of: OF_BULTO_CIEGO
                };
                setnumeroAPI(6);
                setnomColor("primary");

                setCargaBarra(15);


                const start = performance.now();

                tracking.detalle(OF).then(async (resp: any) => {

                    setnumeroAPI(5);
                    setnomColor("primary");
                    setCargaBarra(24);


                    const end = performance.now();

                    console.log(`Tiempo de ejecución tracking detalle : ${(end - start) / 1000} s`);

                    const start2 = performance.now();

                    if (await validarOf(resp)) {


                        const end2 = performance.now();

                        console.log(`Tiempo de ejecución Valida Orden Flete: ${(end2 - start2) / 1000} s`);

                        setnumeroAPI(4);
                        setnomColor("primary");
                        setCargaBarra(45);

                        const start3 = performance.now();
                        if (await validarEstado(resp)) {

                            const end3 = performance.now();

                            console.log(`Tiempo de ejecución Estado OF: ${(end3 - start3) / 1000} s`);


                            setnumeroAPI(3);
                            setnomColor("HOLA");
                            setCargaBarra(65);



                            const start4 = performance.now();
                            let valtop: boolean = (await validarTopdesk(OF_BULTO_CIEGO, modalInfoDto.ticket)).validador;
                            const end4 = performance.now();

                            console.log(`Tiempo de ejecución ValidarTopdesk: ${(end4 - start4) / 1000} s`);


                            if (valtop == true) {
                                setShowU(false);

                                setnumeroAPI(2);
                                setnomColor("primary");

                                setCargaBarra(80);

                                OpenForm(modalInfoDto);



                            } else {
                                setShowU(false);
                                let mensaje: string = (await validarTopdesk(OF_BULTO_CIEGO, modalInfoDto.ticket)).mensaje;

                                AlertaCentral("OFCONTICKET", "", "", "" + OF_BULTO_CIEGO, mensaje);
                            };

                        } else {
                            setShowU(false);
                            AlertaCentral("OFNOALTERABLE", "", "", "" + OF_BULTO_CIEGO);
                        }

                    } else {
                        setShowU(false);
                        AlertaCentral("OFNOEXISTE", "", "", "" + OF_BULTO_CIEGO);
                    }
                });

                setShowU(false);
            } else {

                setShowU(false);
                AlertaCentral("TICKETOFMALA", "", "", "");

            }

        } catch (e) { }

    }

    async function SeleccionAccionInfo(modalInfoDto: ModalInfo) {


        let clase: string = "" + modalInfoDto.categoria;



        let forzado = async () => {

            await setselectorAccionInfo(clase);

        };

        setTimeout(async () => {
            await forzado();
        }, 10);


    };

    async function FuncionFinal(modalInfoDto: ModalInfo) {



        const start = performance.now();

        let url: string = URL_ENVIO_FOMULARIO.URL_ENVIO_FOMULARIO;

        // let OFOriginal: boolean = false;  // esto es en link hacia topdesk -> &oforiginalb=  pasaron a ser state 
        // let OFTieneRTezago: boolean = true; // esto es en link hacia topdesk -> &oftienerezagob=  pasaronb a ser state

        let OrdenFleteFINAL: string = modalInfoDto.ordenFlete;
        let OrdenFleteOriginalFINAL: string = oFOrifinal;
        let NumeroTicketFINAL: string = modalInfoDto.ticket;
        let CodigoEncargoFINAL: string = EncaCodigoBarra;


        console.log("EstadoProcesoCodigo", EstadoProcesoCodigo);


        let codigoEncargo: string = EncaCodigoBarra;
        let estadoProceso: number = Number(EstadoProcesoCodigo);
        let fechacompro: string = FechaComprmiso;


        setnumeroAPI(1);
        setnomColor("primary");
        let UFI: any = await TraeUbicacionFisica(codigoEncargo, estadoProceso, fechacompro);

        console.log(UFI);

        setCargaBarra(90);


        let DentroPlazo: string = String(UFI.dentroPlazo);
        let EstadoEncargo: string = String(UFI.estadoEncargo);
        let NombreUbicacionFisica: string = String(UFI.nombre_ubicacion_fisica);

        let REZAGO = { ORDEN_FLETE: "", DIAS_REZAGO: "", MOTIVO_REZAGO: "", ORDEN_FLETE_ORIGINAL: "" };

        if (AccionRezago) {
            REZAGO.ORDEN_FLETE = OrdenFleteRezago2;
            REZAGO.DIAS_REZAGO = DiasRezago2;
            REZAGO.MOTIVO_REZAGO = MotivoRezago2;
            REZAGO.ORDEN_FLETE_ORIGINAL = OrdenFleteOriginal2;
        } else {
            REZAGO.ORDEN_FLETE = OrdenFleteRezago;
            REZAGO.DIAS_REZAGO = DiasRezago;
            REZAGO.MOTIVO_REZAGO = MotivoRezago;
            REZAGO.ORDEN_FLETE_ORIGINAL = OrdenFleteOriginal;
        }



        let envioFormulario = {
            "ordenFlete": OrdenFleteFINAL,
            "ordenFleteOriginal": OrdenFleteOriginalFINAL,
            "codigoEncargo": CodigoEncargoFINAL,
            "estadoEncargo": EstadoEncargo,
            "numeroTicket": NumeroTicketFINAL,  // &ticketbc
            "dentro_plazo": DentroPlazo,
            "ubicacionFisica": NombreUbicacionFisica,
            "OrdenFleteRezago": REZAGO.ORDEN_FLETE,
            "DiasRezago": REZAGO.DIAS_REZAGO,
            "MotivoRezago": REZAGO.MOTIVO_REZAGO,
            "OrdenFleteOriginalRezago": REZAGO.ORDEN_FLETE_ORIGINAL
        }


        console.log("envioFormulario", envioFormulario);


        if (OFOriginal) {

            url = url + "&odfloriginal=" + envioFormulario.ordenFleteOriginal + "&encargo=" + envioFormulario.codigoEncargo + "&estado=" + envioFormulario.estadoEncargo + "&ubicacion=" + envioFormulario.ubicacionFisica + "&dentroplazo=" + envioFormulario.dentro_plazo + "&oforiginalb=" + OFOriginal + "&oftienerezagob=" + OFTieneRTezago + "&ticketbc=" + envioFormulario.numeroTicket;

            //  console.log("Se usa la orden de flete original propocionada");

            if (OFTieneRTezago) {
                //  console.log("Se utiliza la orden de flete original y los datos de rezago");

                url = url + "&ordenfelterezago=" + envioFormulario.OrdenFleteRezago + "&diasrezago=" + envioFormulario.DiasRezago + "&motivorezago=" + envioFormulario.MotivoRezago + "&ordenfleteoriginalrezago=" + envioFormulario.OrdenFleteOriginalRezago;

            } else { }

        } else {
            //  console.log("Se usa la orden de flete que viene del ticket");

            url = url + "&odfl=" + envioFormulario.ordenFlete + "&encargo=" + envioFormulario.codigoEncargo + "&estado=" + envioFormulario.estadoEncargo + "&ubicacion=" + envioFormulario.ubicacionFisica + "&dentroplazo=" + envioFormulario.dentro_plazo + "&oforiginalb=" + OFOriginal + "&oftienerezagob=" + OFTieneRTezago + "&ticketbc=" + envioFormulario.numeroTicket;

            if (OFTieneRTezago) {
                //  console.log("Se utiliza la orden de flete del ticket y los datos de rezago");

                url = url + "&ordenfelterezago=" + envioFormulario.OrdenFleteRezago + "&diasrezago=" + envioFormulario.DiasRezago + "&motivorezago=" + envioFormulario.MotivoRezago + "&ordenfleteoriginalrezago=" + envioFormulario.OrdenFleteOriginalRezago;

            } else { }

        }


        const end = performance.now();



        return url;

    }

    async function OpenForm(modalInfoDto: ModalInfo) {

        let URL = await FuncionFinal(modalInfoDto);

       await  abrirFormulario(URL);

        setnumeroAPI(0);
        setnomColor("primary");

        setCargaBarra(100);

    }



    async function cargaDatosTicketRecuperado(modalInfoDto: any) {


        try {

            if (modalInfoDto.ticketRecuperado != "") {

                let Datos = await Topdesk.TraeTrickeResuleto(modalInfoDto.ticketRecuperado).then((resp) => {
                    return resp.data;
                });

                let ejecutar = async () => {
                    setrecuperacionTicket(Datos.number);
                    setrecuperacionAgente(Datos.requester.name);
                }

                await ejecutar();
            } else {

                let ejecutar = async () => {
                    setrecuperacionTicket("");
                    setrecuperacionAgente("");
                }

                await ejecutar();


            }
            setShowU(false);
        } catch (e) { }
    }

    async function forzarOFOriginal() {

        const ejecutar = async () => {
            await setOFOriginal(true);
        };

        await ejecutar();

    }

    async function forzarOFTieneRTezago() {
        const ejecutar = async () => {
            await setOFTieneRTezago(true);
        }
        await ejecutar();
    }

    return (

        <ConfigProvider locale={esES}>

            <>
                {/* MENU */}
                <Menu titulo="Búsqueda Bulto Ciego" logo="" />

                {/* INFORMACION */}
                <Alert className="ml-1" show={mostrar} variant="success">
                    <Alert.Heading> Información </Alert.Heading>
                    <p>
                        El sistema de búsqueda de <b> Bulto Ciego </b> permite encontrar encargos mediante su identificación visual.
                    </p>
                    <p>Para lo cual diponibiliza una busqueda avanzada con distintos filtros de clasificación.</p>
                    <p>Una vez que se identifique correctamente el encargo, se pueden realizar 4 acciones para recuperarlo.</p>

                    <div className="d-flex justify-content-end">
                        <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="overlay-example">Cerrar Información</Tooltip>}
                        >
                            <Button
                                className="outline-info"
                                onClick={() => { setMostrar(false) }}
                            >
                                <FontAwesomeIcon icon={faAngleUp} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </Alert>

                {!mostrar && (
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="overlay-example">Abrir Información</Tooltip>}
                    >
                        <Button
                            shape="circle"
                            onClick={() => setMostrar(true)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </Button>
                    </OverlayTrigger>
                )}

                <hr style={{ color: Color_Verde }} />

                {/* // SELECCION DE BUSQUEDA */}

                <Container

                    fluid="xl"
                    className="rounded pt-3 pb-3 shadow"
                    style={{ background: Color_Main_BackGround }}
                >
                    <div className="text-center justify-content-center" >
                        <Row className="text-center justify-content-center">
                            <Col></Col>
                            <Col>
                                <Col>
                                    <CheckCircleOutlined style={{ color: Color_Verde, fontSize: "25px" }} />
                                </Col>
                                <Col>
                                    <h5 style={{ color: Color_Verde }} > Selección de Clasificación </h5>
                                </Col>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>

                    <div className=" border" style={{ background: Color_Blanco }}>

                        <Container fluid="xl" className="text-center mt-3" >
                            <Row className="row">
                                <Col>
                                    {checkedSucursal ?
                                        <Button type="primary"
                                            icon={<CheckCircleFilled />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Sucursales }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame1();
                                                setEleccion("sucursal");
                                                setTitulo("X Sucursal");
                                                setColor(Color_Sucursales);
                                            }}
                                        >
                                            Sucursal {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreSucursal}</b>
                                        </Button>
                                        :
                                        <Button type="primary"
                                            icon={<SearchOutlined />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Sucursales }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame1();
                                                setEleccion("sucursal");
                                                setTitulo("X Sucursal");
                                                setColor(Color_Sucursales);
                                            }}
                                        >
                                            Sucursal {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreSucursal}</b>
                                        </Button>
                                    }
                                </Col>
                                <Col>
                                    {checkedColor ?
                                        <Button type="primary"
                                            icon={<CheckCircleFilled />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Colores }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame2();
                                                setEleccion("color");
                                                setTitulo("X Color");
                                                setColor(Color_Colores)
                                            }}
                                        >
                                            Color {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreColors}</b>
                                        </Button>


                                        :
                                        <Button type="primary"
                                            icon={<SearchOutlined />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Colores }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame2();
                                                setEleccion("color");
                                                setTitulo("X Color");
                                                setColor(Color_Colores)
                                            }}
                                        >  Color {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreColors}</b>  </Button>

                                    }
                                </Col>
                                <Col>

                                    {checkedTipoTicket ?
                                        <Button type="primary"
                                            icon={<CheckCircleFilled />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Tickets }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame3();
                                                setEleccion("tipoticket");
                                                setTitulo("X Tipo Ticket");
                                                setColor(Color_Tickets)
                                            }}
                                        > Tipo Ticket  {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreTipoTk}</b>  </Button>
                                        :
                                        <Button type="primary"
                                            icon={<SearchOutlined />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Tickets }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame3();
                                                setEleccion("tipoticket");
                                                setTitulo("X Tipo Ticket");
                                                setColor(Color_Tickets)
                                            }}
                                        > Tipo Ticket  {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreTipoTk}</b>  </Button>}


                                </Col>
                            </Row>
                        </Container>
                        <Container fluid="xl" className="text-center mt-3 mb-3">
                            <Row className="row">

                                <Col>
                                    {checkedRangoFecha ?
                                        <Button type="primary"
                                            icon={<CheckCircleFilled />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Fechas }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame4();
                                                setEleccion("fecha");
                                                setTitulo("X Randgo de Fechas");
                                                setColor(Color_Fechas)
                                            }}
                                        > Rango Fecha  {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreFecha}</b>  </Button>
                                        :
                                        <Button type="primary"
                                            icon={<SearchOutlined />}
                                            style={{ color: Color_Blanco, backgroundColor: Color_Fechas }}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame4();
                                                setEleccion("fecha");
                                                setTitulo("X Randgo de Fechas");
                                                setColor(Color_Fechas)
                                            }}
                                        > Rango Fecha  {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombreFecha}</b>  </Button>}



                                </Col>

                                <Col>

                                    {checkedPalabraClave ?
                                        <Button type="primary" ghost disabled
                                            icon={<CheckCircleFilled />}
                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame5();
                                                setTitulo("X Observaciones");
                                                setColor(Color_PalabrasClaves)
                                            }}
                                        > Palabra Clave {" "}&nbsp;&nbsp;&nbsp;
                                            <b>{nombrePalabra}</b>
                                        </Button>
                                        :
                                        <Button type="primary" ghost disabled
                                            icon={<SearchOutlined />}

                                            className="d-flex justify-content-center shadow"
                                            block
                                            onClick={() => {
                                                soloFrame5();
                                                setTitulo("X Observaciones");
                                                setColor(Color_PalabrasClaves)
                                            }}
                                        > Palabra Clave {" "} &nbsp;
                                            <b>{nombrePalabra}</b>
                                        </Button>}


                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </div>

                    <Container>
                        <Col className="text-center mt-2 md-2 justify-content-center">
                            <Button
                                className="shadow"
                                style={{ color: Color_Limpieza, backgroundColor: Color_BackGround_Limpieza }}
                                onClick={() => {
                                    cancelar()
                                }}>
                                <SyncOutlined style={{ color: Color_Limpieza }} />
                                <b> Limpiar Busqueda </b>
                            </Button>
                        </Col>
                    </Container>


                </Container>


                {/* // FRAME DE BUSQUEDA */}

                <Container
                    fluid="xl"
                    className="rounded mt-3 pt-3 pb-3  shadow"
                    style={{ background: Color_Main_BackGround }}
                >
                    <div className="text-center justify-content-center">
                        <Row className="row justify-content-center">
                            <Col></Col>
                            <Col>
                                <Col>
                                    <QuestionCircleOutlined style={{ color: Color_Verde, fontSize: "25px" }} />
                                </Col>
                                <Col>
                                    <h5 style={{ color: Color_Verde }} > Criterios de Búsqueda </h5>
                                </Col>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>

                    <Container className="border" style={{ background: Color_Blanco }}>

                        <Row className="text-center" style={{ color: Color_Blanco, backgroundColor: `${color}` }}>
                            <Col>{titulo}</Col>
                        </Row>

                        <Container hidden={frame1} className="justify-content-center">

                            <Row className="justify-content-center mt-3 mb-3">
                                <Col>
                                    <label> Búsqueda de Sucursal </label>
                                </Col>
                                <Col>
                                    <Select
                                        key="agen"
                                        showSearch
                                        placeholder="Seleccionar una Sucursal"
                                        optionFilterProp="children"
                                        onChange={onChangeAgencia}
                                        onSearch={onSearchAgencia}
                                        filterOption={(input, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={
                                            OptionAgencias
                                        }
                                        style={{ width: '210px' }}
                                    />

                                </Col>

                                <Col >
                                    <Checkbox
                                        key="checkcolor"
                                        onChange={handleSelectSucursal}
                                    >
                                        {" "} Sumar Filtro {" "}
                                    </Checkbox>
                                </Col>

                            </Row>



                        </Container>

                        <Container hidden={frame2} className="justify-content-center">
                            <Row className="justify-content-md-center mt-3 mb-3" >
                                <Col >
                                    <label> Búsqueda por Color </label>
                                </Col>
                                <Col>
                                    <Select
                                        key="color"
                                        showSearch
                                        placeholder="Seleccionar un color"
                                        optionFilterProp="children"
                                        onChange={onChangeColor}
                                        onSearch={onSearchColor}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={OptionColores}
                                        style={{ width: '210px' }}
                                    />

                                </Col>

                                <Col>

                                    <Checkbox
                                        key="checkcolor"
                                        onChange={handleSelectColor}
                                    >
                                        {" "} Sumar Filtro {" "}
                                    </Checkbox>

                                </Col>

                            </Row>

                        </Container>

                        <Container hidden={frame3} className="justify-content-center">

                            <Row className="justify-content-md-center mt-3 mb-3">
                                <Alert variant="danger">
                                    <p><b>MINI TICKET  </b> {" "} / {" "} <u>Peso</u> {" "} <em>Min: 0.1  Máx : 50 </em> {" "} <b>&nbsp;-</b> {" "} <u>Alto</u> {" "}<em> Min: 0.1 Máx: 50 </em>{" "} <b>&nbsp;-</b> {" "} <u>Largo</u> {" "}<em> Min: 0.1 Máx: 50 </em>{" "} <b>&nbsp;-</b> {" "} <u>Ancho</u> {" "}<em> Min: 0.1 Máx: 50  </em></p>
                                    <p><b>BIG TICKET   </b> {" "} / {" "} <u>Peso</u> {" "} <em>Min: 51   Máx : 100</em> {" "} <b>&nbsp;-</b> {" "} <u>Alto</u> {" "}<em> Min: 51 Máx: 100 </em>{" "} <b>&nbsp;-</b> {" "} <u>Largo</u> {" "}<em> Min: 51 Máx: 100 </em>{" "} <b>&nbsp;-</b> {" "} <u>Ancho</u> {" "}<em> Min: 51 Máx: 100  </em></p>
                                    <p><b>GIGA TICKET  </b> {" "} / {" "} <u>Peso</u> {" "} <em>Min: 101  Máx : +  </em> {" "} <b>&nbsp;-</b> {" "} <u>Alto</u> {" "}<em> Min: 101 Máx: +  </em>{" "} <b>&nbsp;-</b> {" "} <u>Largo</u> {" "}<em> Min: 101 Máx: +  </em>{" "} <b>&nbsp;-</b> {" "} <u>Ancho</u> {" "}<em> Min: 101 Máx: +   </em></p>
                                </Alert>
                            </Row>


                            <Row className="justify-content-md-center mt-3 mb-3">
                                <Col>
                                    <label> Tipo Ticket </label>
                                </Col>
                                <Col>
                                    <Select
                                        key="ticket"
                                        placeholder="Seleccionar un Ticket"
                                        style={{ width: '210px' }}
                                        onChange={handleTicket}
                                        options={[
                                            { value: "MINI TICKET", lavel: "MINI TICKET" },
                                            { value: "BIG TICKET", lavel: "BIG TICKET" },
                                            { value: "GIGA TICKET", lavel: "GIGA TICKET" }
                                        ]}></Select>

                                </Col>
                                <Col>

                                    <Checkbox
                                        value="checkticket"

                                        onChange={handleSelectTipoTicket}
                                    >
                                        {" "} Sumar Filtro {" "}
                                    </Checkbox>

                                </Col>

                            </Row>

                        </Container>

                        <Container hidden={frame4} className="justify-content-center">
                            <Row className="justify-content-md-center mt-3 mb-3">
                                <Alert variant="warning"> Sólo se pueden seleccionar rangos de fechas de 15 dias de diferencia. </Alert>
                            </Row>
                            <Row className="justify-content-md-center mt-3 mb-3">
                                <Col>
                                    <label> Observaciones </label>
                                </Col>
                                <Col className="col-6">
                                    <Space direction="vertical" size={12}>

                                        <RangePicker
                                            key="fechas"
                                            format={dateFormat}
                                            onChange={onChangeDP}
                                        />
                                    </Space>

                                </Col>
                                <Col>

                                    <Checkbox
                                        key="checkfechas"
                                        onChange={handleSelectRangoFecha}
                                    >
                                        {" "} Sumar Filtro {" "}
                                    </Checkbox>


                                </Col>
                            </Row>

                        </Container>

                        <Container hidden={frame5} className="justify-content-center">
                            <Row className="justify-content-md-center mt-3 mb-3">

                                <Alert variant="info"> Este Filtro funciona independiente de los demás, por lo que no es posible combinarlo. </Alert>
                            </Row>
                            <Row className="justify-content-md-center mt-3 mb-3">
                                <Col>
                                    <label> Observaciones </label>
                                </Col>
                                <Col>
                                    <Input
                                        key="InputDePalabras"
                                        placeholder="Escribir palabra a buscar"
                                    />

                                </Col>
                                <Col>
                                    <Checkbox
                                        onChange={handleSelectPalabraClave}
                                        key="checkPalabras"

                                    >
                                        {" "} Sumar Filtro {" "}
                                    </Checkbox>
                                </Col>



                            </Row>

                        </Container>

                    </Container>

                    <hr style={{ color: Color_Verde }} />

                    <div className="w-70 text-center justify-content-center  mt-2 mb-2">

                        {permitirBuscar ?



                            <Button
                                className="shadow"
                                icon={<SearchOutlined />}
                                style={{ color: Color_Blanco, backgroundColor: Color_Verde }}
                                onClick={() => {

                                    BusquedaConcentrada(eleccion);

                                }}
                            >
                                Buscar
                            </Button>

                            :


                            <Button
                                ghost
                                disabled
                                className="shadow"
                                icon={<SearchOutlined />}
                            //    style={{ color: "white", backgroundColor: Color_Verde }}

                            >
                                Buscar
                            </Button>




                        }


                    </div>

                </Container>

                {/* 
            // SECCION DE Galeria */}

                <Container fluid="xl" className="rounded mt-3 pt-3 pb-3 shadow"
                    style={{ background: Color_Main_BackGround }}>

                    <div className="text-center justify-content-center">
                        <Row className="justify-content-center mb-3">

                            <Col className="col-3 d-flex justify-content-center mb-2">
                                <Button
                                    block
                                    type="primary"
                                    icon={<PictureOutlined />}
                                    style={{ color: Color_Limpieza, backgroundColor: Color_BackGround_Limpieza }}
                                    className="justify-content-between ">
                                    {" "}  <b>Galeria</b> {" "} </Button>
                            </Col>
                        </Row>
                    </div>


                    <Row>
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-end">
                            <Button type="primary" style={{ color: Color_Limpieza, backgroundColor: Color_BackGround_Limpieza }} className="justify-content-between " disabled>
                                Total Tickets : <b>{" "}{totalTK}{" "}</b>
                            </Button>
                            <Button type="primary" style={{ color: Color_Limpieza, backgroundColor: Color_BackGround_Limpieza }} className="justify-content-between " disabled>
                                Cantidad por Página
                            </Button>
                            <div className="vr" />
                            <Form.Select size="sm" style={{ width: "80px" }} className=""
                                onChange={(e) => { setCantidadPorPagina(+e.target.value) }}>
                                <option value={16}>16</option>
                                <option value={32}>32</option>
                                <option value={300}>300</option>
                            </Form.Select>

                        </Stack>
                    </Row>

                    {/* CARGA DE GALERIA DINAMICA */}


                    <Row hidden={!busquedaCiega}>

                        {

                            currentImages.map((modalInfoDto: ModalInfo, index: any) => {



                                let largo = modalInfoDto.imagenes.length;

                                if (largo != 0) {

                                    let clase = modalInfoDto.categoria;
                                    let color = "";
                                    let texto = "";

                                    if (clase == "null") {
                                        color = Color_Ticket_SinEstado;
                                    } else if (clase == TK_01) {
                                        color = Color_Ticket_Original;
                                        texto = "Recuperado Redestinado";
                                    } else if (clase == TK_02) {
                                        color = Color_Ticket_Redestinado;
                                        texto = "Recuperado Devuelto";
                                    } else if (clase == TK_03) {
                                        color = Color_Ticket_Devuelto;
                                        texto = "Recuperado Sacar a Reparto";
                                    } else if (clase == TK_04) {
                                        color = Color_Ticket_Rezago;
                                        texto = "Recuperado Cambio Tipo Entrega";
                                    } else {
                                        color = Color_Ticket_Sobrante;
                                        texto = "Recuperado Despachar Sucursal Destino";
                                    }

                                    return (

                                        <Col key={index} md={4} sm={6} xs={12}>
                                            <Card className="ml-2 mr-2 shadow mt-3" >

                                                <Card.Header className="text-center ml-2 mr-2" style={{ background: color }}>
                                                    <Container>
                                                        <Col>
                                                            <Row>
                                                                <label style={{ color: Color_Verde }} > <b> Ticket </b> </label>
                                                            </Row>
                                                            <Row>
                                                                <h5 className="mt-0" style={{ color: Color_Verde }} > {modalInfoDto.ticket} </h5>
                                                            </Row>
                                                            <Row>
                                                                <label className="mt-0" style={{ color: Color_Verde, fontSize: 15 }} >  {texto} </label>
                                                            </Row>

                                                        </Col>
                                                    </Container>


                                                </Card.Header>

                                                <Row className="text-center justify-content-center mt-3">
                                                    <Image.PreviewGroup>
                                                        <Image width={350} src={modalInfoDto.imagenes[0]} fallback={NoImage} alt={'Image' + index + random} key={'Image' + index + random} />
                                                    </Image.PreviewGroup>
                                                </Row>
                                                <Card.Body className="text-center">
                                                    <Col>
                                                        <Row>
                                                            <label> Catidad fotografías : {largo} </label>
                                                        </Row>
                                                        <Row>

                                                            <Tooltip placement="bottom" title="Click Para Información">
                                                                <Button type="primary"
                                                                    icon={<ProfileFilled />}
                                                                    style={{ color: Color_Blanco, backgroundColor: Color_Verde }}
                                                                    onClick={async () => {

                                                                        setColorCardHeader(await ActualizaColor(modalInfoDto));
                                                                        SeleccionAccionInfo(modalInfoDto);
                                                                        ordenDeCarga(modalInfoDto);
                                                                        cargaDatosTicketRecuperado(modalInfoDto);
                                                                        setrecuperacionTipo(texto);
                                                                        setShowU(true);

                                                                    }}

                                                                    className="justify-content-between"
                                                                >
                                                                    DETALLE
                                                                </Button>
                                                            </Tooltip>
                                                        </Row>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                    )

                                } else {



                                    let clase = modalInfoDto.categoria;
                                    let color = "";
                                    let texto = "";

                                    if (clase == "null") {
                                        color = Color_Ticket_SinEstado;
                                    } else if (clase == TK_01) {
                                        color = Color_Ticket_Original;
                                        texto = "Recuperado Redestinado";
                                    } else if (clase == TK_02) {
                                        color = Color_Ticket_Redestinado;
                                        texto = "Recuperado Devuelto";
                                    } else if (clase == TK_03) {
                                        color = Color_Ticket_Devuelto;
                                        texto = "Recuperado Sacar a Reparto";
                                    } else if (clase == TK_04) {
                                        color = Color_Ticket_Rezago;
                                        texto = "Recuperado Cambio Tipo Entrega";
                                    } else {
                                        color = Color_Ticket_Sobrante;
                                        texto = "Recuperado Despachar Sucursal Destino";
                                    }



                                    return (
                                        <Col key={index} md={4} sm={6} xs={12}>
                                            <Card className="ml-2 mr-2 shadow mt-3">
                                                <Card.Header className="text-center" style={{ background: color }}>
                                                    <Container>
                                                        <Col>
                                                            <Row>
                                                                <label style={{ color: Color_Verde }} > <b> Ticket </b> </label>
                                                            </Row>
                                                            <Row>
                                                                <h5 className="mt-0" style={{ color: Color_Verde }} > {modalInfoDto.ticket} </h5>
                                                            </Row>
                                                            <Row>
                                                                <label className="mt-0" style={{ color: Color_Verde, fontSize: 15 }} >  {texto} </label>
                                                            </Row>

                                                        </Col>
                                                    </Container>
                                                </Card.Header>
                                                <Row className="text-center justify-content-center mt-3">
                                                    <Image.PreviewGroup>
                                                        <Image width={350} src={NoImage} fallback={NoImage} alt={'Image' + index} key={'Image' + index + random} />
                                                    </Image.PreviewGroup>
                                                </Row>
                                                <Card.Body className="text-center">
                                                    <Col>
                                                        <Row>
                                                            <label> Catidad fotografías : {largo} </label>
                                                        </Row>
                                                        <Row>
                                                            <Tooltip placement="bottom" title="Click Para Información">
                                                                <Button type="primary"
                                                                    icon={<ProfileFilled />}
                                                                    style={{ color: Color_Blanco, backgroundColor: Color_Verde }}
                                                                    onClick={async () => {

                                                                        setColorCardHeader(await ActualizaColor(modalInfoDto));
                                                                        SeleccionAccionInfo(modalInfoDto);
                                                                        ordenDeCarga(modalInfoDto);
                                                                        cargaDatosTicketRecuperado(modalInfoDto);
                                                                        setrecuperacionTipo(texto);
                                                                        setShowU(true);

                                                                    }}
                                                                    className="justify-content-between"
                                                                >
                                                                    DETALLE
                                                                </Button>
                                                            </Tooltip>
                                                        </Row>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </Col>)

                                }
                            })

                        }

                    </Row>

                    <Pagination size="sm" className="mt-3" >

                        {Array.from({ length: totalPages }, (_, i) => (
                            <Pagination.Item
                                key={i}
                                active={i + 1 === currentPage}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Pagination.Item>
                        ))

                        }
                        {" "}

                    </Pagination>

                </Container>

                {/* MODAL LOADING */}

                <Modal
                    show={showU}
                    size="sm"
                    backdrop="static"
                    keyboard={false}
                    name="loading"

                >
                    <Modal.Header className="text-center justify-content-center">
                        <img alt="Starken" src={logoSTK} style={{ height: "45px" }} />
                    </Modal.Header>
                    <Modal.Body  >
                        <Container className="text-center">
                            <Spinner animation="border" role="status" variant="success" size="sm" />
                            <Spinner animation="border" role="status" variant="success" />
                        </Container>
                        <Container className="text-center">
                            <h6 style={{ color: Color_Verde }}>Cargando...</h6>
                        </Container>
                        <hr />
                        <Container className="text-center">
                            <h5 style={{ color: Color_Verde }}>{mensaje}</h5>
                        </Container>
                    </Modal.Body>
                </Modal>

                {/* MODAL Detalle Información Ticket*/}

                <Modal
                    show={show}
                    fullscreen
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    name="detalle"
                    className="text-center justify-content-center shadow"
                >

                    {/* CABECERA */}

                    <Modal.Header style={{ background: colorCardHeader }}>

                        <Modal.Title className="container text-center justify-content-center" style={{ color: Color_Verde }}>
                            <FormOutlined />{" "}
                            DETALLE TICKET {modalInfoDto.ticket} {" "} </Modal.Title>
                    </Modal.Header>

                    {/* BODY */}

                    <Modal.Body style={{ background: colorCardHeader }} >

                        <Container className="text-center">

                            <Row className="text-center justify-content-center">
                                <Image.PreviewGroup>
                                    {
                                        modalInfoDto.imagenes
                                    }
                                </Image.PreviewGroup>
                            </Row>


                            {/* informacion ticket */}
                            <Row>
                                {/* INFORMACION PERMANENTE */}
                                <Container
                                    className="rounded mt-3 pt-3 pb-3 w-40 shadow"
                                    style={{ background: Color_Blanco }}
                                >
                                    <Row>
                                        <Col className="col-4 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Ticket TopDesk
                                            </b></label>
                                        </Col>
                                        <Col>
                                            <input size={60} disabled
                                                value={modalInfoDto.ticket}
                                            ></input>
                                        </Col>
                                    </Row>
                                    <hr style={{ color: Color_Verde }} />
                                    <Row>
                                        <Col className="col-4 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Orden de Flete
                                            </b></label>
                                        </Col>
                                        <Col>
                                            <input size={60} disabled
                                                value={modalInfoDto.ordenFlete}
                                            ></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Creado por
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.creador}
                                            ></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Sucursal
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.agencia}
                                            ></input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Fecha Creación
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.fecha}
                                            ></input>
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Hora Creación
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.hora}
                                            ></input>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Tipo Ticket
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.tipoTicket}
                                            ></input>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-4 mt-2 d-flex justify-content-end">
                                            <label style={{ color: Color_Verde }} ><b>
                                                Color
                                            </b></label>
                                        </Col>
                                        <Col className="mt-2">
                                            <input size={60} disabled
                                                value={modalInfoDto.color}
                                            ></input>
                                        </Col>
                                    </Row>

                                    <hr style={{ color: Color_Verde }} />

                                    <Row >
                                        <label style={{ color: Color_Verde }}><b>
                                            INFORMACIÓN DE SOLICITUD
                                        </b></label>
                                    </Row>
                                    <Row >
                                        <Container  >
                                            <InputGroup>
                                                <Form.Control
                                                    as="textarea"
                                                    value={modalInfoDto.obs}
                                                    className="mt-1"
                                                    disabled
                                                    style={{ color: Color_Negro, resize: 'none', height: 120 }}

                                                />

                                            </InputGroup>
                                            <Col className="mt-1 d-flex justify-content-end">
                                                <label style={{ color: Color_Gris }}>{modalInfoDto.largoObs}</label>
                                            </Col>
                                        </Container>
                                    </Row>




                                    <Container hidden={enableViewRezago} >



                                        <hr style={{ color: Color_Verde }} />

                                        <Row >
                                            <label style={{ color: Color_Verde }}><b>
                                                Fechas Orden Flete
                                            </b></label>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col className="col-4 mt-2 d-flex justify-content-end">
                                                        <label style={{ color: Color_Verde }} ><b>
                                                            Creación
                                                        </b></label>
                                                    </Col>
                                                    <Col className="mt-2">
                                                        <input size={11} disabled
                                                            value={modalInfoDto.fechaCreacionOf}
                                                        ></input>
                                                    </Col>

                                                </Row>

                                            </Col>

                                            <Col>
                                                <Row>
                                                    <Col className="col-4 mt-2 d-flex justify-content-end">
                                                        <label style={{ color: Color_Verde }} ><b>
                                                            Envio Rezago
                                                        </b></label>
                                                    </Col>
                                                    <Col className="mt-2">
                                                        <input size={11} disabled
                                                            value={modalInfoDto.fechaEnvioRezago}
                                                        ></input>
                                                    </Col>

                                                </Row>
                                            </Col>

                                        </Row>
                                    </Container>

                                </Container>

                                {/* SECCION DE INFORMACION DE RECUPERACION */}

                                {selectorAccionInfo == "null" ? (

                                    <>

                                        {/* SECCION DE REZAGO   */}

                                        <Container
                                            hidden={!enableViewRezago}
                                            className=" rounded mt-3 pt-3 pb-3 w-40 shadow"
                                            style={{ background: Color_Blanco }}
                                        >


                                            <Row >
                                                <Col>
                                                    <h6 style={{ color: Color_Verde }}>
                                                        <b> ESTADO REZAGO ORDEN FLETE TICKET </b>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-4 mt-2 d-flex justify-content-end">
                                                    <label style={{ color: Color_Verde }} ><b>
                                                        OF Rezago
                                                    </b></label>
                                                </Col>
                                                <Col className="mt-2">
                                                    <input size={60} disabled
                                                        value={OrdenFleteRezago}
                                                    ></input>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-4 mt-2 d-flex justify-content-end">
                                                    <label style={{ color: Color_Verde }} ><b>
                                                        OF Original
                                                    </b></label>
                                                </Col>
                                                <Col className="mt-2">
                                                    <input size={60} disabled
                                                        value={OrdenFleteOriginal}
                                                    ></input>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-4 mt-2 d-flex justify-content-end">
                                                    <label style={{ color: Color_Verde }} ><b>
                                                        Dias
                                                    </b></label>
                                                </Col>
                                                <Col className="mt-2">
                                                    <input size={60} disabled
                                                        value={DiasRezago}
                                                    ></input>
                                                </Col>
                                            </Row>

                                            <hr style={{ color: Color_Verde }} />

                                            <Row className="mt-2">
                                                <label style={{ color: Color_Verde }}> <b>
                                                    Motivo
                                                </b> </label>
                                            </Row>

                                            <Row>
                                                <Container >
                                                    <InputGroup>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="mt-1"
                                                            value={MotivoRezago}
                                                            disabled
                                                            style={{ color: Color_Negro, resize: 'none', height: 120 }}

                                                        />

                                                    </InputGroup>
                                                    <Col className="mt-1 d-flex justify-content-end">
                                                        <label style={{ color: Color_Gris }}>{LargoMotivo}</label>
                                                    </Col>
                                                </Container>
                                            </Row>

                                        </Container>

                                        {/* SECCION DE GESTION */}
                                        <Container
                                            hidden={enableViewGestionar}
                                            className=" rounded mt-3 pt-3 pb-3 w-40 shadow"
                                            style={{ background: Color_Blanco }}
                                        >

                                            <Row >
                                                <Col  >
                                                    <h4 style={{ color: Color_Verde }}>
                                                        GESTIONAR
                                                    </h4>
                                                </Col>
                                            </Row>



                                            <div className="container">
                                                <DataTable columns={columns}
                                                    data={arrayEncargos}
                                                    dense
                                                    fixedHeader
                                                    striped
                                                    subHeaderAlign={Alignment.CENTER}
                                                />
                                            </div>

                                        </Container>


                                    </>
                                ) : (

                                    <>

                                        {/* SECCION INFORMACION RECUPERADO */}

                                        <Container
                                            className=" rounded mt-3 pt-3 pb-3 w-40 shadow"
                                            style={{ background: Color_Blanco }}
                                        >

                                            <Row >
                                                <Col  >
                                                    <h6 style={{ color: Color_Verde }}>
                                                        <b> INFORMACIÓN </b>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <hr style={{ color: Color_Verde }} />

                                            <Container>

                                                <Row>
                                                    <Col className="col-4 mt-2 d-flex justify-content-end">
                                                        <label style={{ color: Color_Verde }} ><b>
                                                            Tipo Recuperacion :
                                                        </b></label>
                                                    </Col>
                                                    <Col className="mt-2">
                                                        <input size={60} disabled
                                                            value={recuperacionTipo}
                                                        ></input>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="col-4 mt-2 d-flex justify-content-end">
                                                        <label style={{ color: Color_Verde }} ><b>
                                                            Bulto ya recuperdo, por:
                                                        </b></label>
                                                    </Col>
                                                    <Col className="mt-2">
                                                        <input size={60} disabled
                                                            value={recuperacionAgente}
                                                        ></input>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="col-4 mt-2 d-flex justify-content-end">
                                                        <label style={{ color: Color_Verde }} ><b>
                                                            Ticket de Recuperacion
                                                        </b></label>
                                                    </Col>
                                                    <Col className="mt-2">
                                                        <input size={60} disabled
                                                            value={recuperacionTicket}
                                                        ></input>
                                                    </Col>
                                                </Row>

                                            </Container>

                                        </Container>

                                    </>
                                )}

                            </Row>
                        </Container>

                    </Modal.Body>

                    {/* FOOTER */}

                    <Modal.Footer style={{ background: colorCardHeader }} >

                        <Button danger type="primary" onClick={() => { handleClose(); setShowU(false) }}> Cerrar
                            <CloseCircleOutlined />
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL GESTIONAR */}

                <Modal
                    show={show1}
                    size="lg"
                    onHide={handleClose1}
                    backdrop="static"
                    keyboard={false}
                    name="loading"
                    className="text-center justify-content-center shadow"
                >
                    <Modal.Header style={{ background: Color_Encabezado }}>
                        <Modal.Title className="container text-center justify-content-center" style={{ color: Color_Verde }}>
                            <EditFilled />{" "}Gestión de Ticket
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: Color_Main_BackGround }} >

                        <Container
                            className="rounded mt-3 pt-3 pb-3 w-40 shadow"
                            style={{ background: Color_Blanco }}
                        >

                            <Container className="justify-content-center" >
                                <Row className="justify-content-center" >
                                    <Button disabled
                                        style={{ color: Color_Verde, backgroundColor: Color_BackGround_Limpieza }}
                                    >
                                        <b> INGRESAR ORDEN DE FLETE ORIGINAL </b></Button>
                                    <Container className="justify-content-center">

                                        <Col className="justify-content-center mt-3">

                                            {estadoBotonBultoCiego ? (<>

                                                <Row >

                                                    <form onSubmit={handleSubmit}>
                                                        <Row>
                                                            <Col>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    pattern="^[0-9]*$"
                                                                    maxLength={9}
                                                                    minLength={3}
                                                                    placeholder="Ej: 12345678"
                                                                    required
                                                                    onChange={(e: any) => {

                                                                        if (e.target.value !== "e") {
                                                                            setOFOrifinal(e.target.value);
                                                                        }

                                                                    }


                                                                    } />{" "}
                                                            </Col>
                                                            <Col className="">
                                                                <button
                                                                    className=" btn btn-outline-success "
                                                                    style={{ color: Color_Verde }}

                                                                >
                                                                    Comprobar{" "}
                                                                    <RocketOutlined style={{ color: Color_Verde, fontSize: "20px" }} />
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                </Row>



                                            </>) : (<>

                                                <input disabled />{" "}
                                                <Button ghost disabled>
                                                    Comprobar{" "}
                                                    <RocketOutlined style={{ color: Color_Gris, fontSize: "20px" }} />
                                                </Button>
                                            </>
                                            )}

                                        </Col>
                                        <Col>
                                            <Checkbox className="mt-2" style={{ color: Color_Verde }} onChange={onChange} >{" "} <b>SIN</b> Orden de Flete Original</Checkbox>
                                        </Col>

                                    </Container>
                                </Row>
                            </Container>

                            <hr style={{ color: Color_Verde }} />
                            <Container className="w-70" >


                                {estadoBotonBultoCiego ? (
                                    <Button
                                        ghost
                                        disabled
                                        className="w-70 mt-2"
                                    >
                                        <CheckCircleFilled />
                                        {" "}
                                        Crear Ticket Para Recuperar Bulto
                                    </Button>
                                ) : (
                                    <Button

                                        className="w-70 mt-2" style={{ background: Color_Verde, color: Color_Blanco }}

                                        disabled={DisableBotonEnvio}

                                        onClick={() => {

                                            if (estadoBotonBultoCiego) {

                                            } else {
                                                setDisableBotonEnvio(true);
                                                // cargaProgresBar();
                                                AccionIngresarOfTicket(modalInfoDto);
                                            }

                                        }}
                                    >
                                        <CheckCircleFilled />
                                        {" "}
                                        Crear Ticket Para Recuperar Bulto
                                    </Button>
                                )}

                            </Container>
                            <Container >
                                <Row>
                                    <Col className="col-1">

                                        {nomColor == "primary" ? (
                                            <Button shape="circle" type="primary" hidden={!DisableBotonEnvio}>{numeroAPI}</Button>
                                        ) : (

                                            <Button shape="circle" type="primary" hidden={!DisableBotonEnvio} style={{ background: Color_Verde, color: Color_Blanco }}>{numeroAPI}</Button>
                                        )
                                        }

                                    </Col>
                                    <Col>
                                        <ProgressBar className="mt-2 mb-2" hidden={!DisableBotonEnvio} striped variant="success" now={CargaBarra} />
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer style={{ background: Color_Footer }} >
                        <Button danger type="primary"
                            onClick={() => {
                                handleClose1();
                                setShowU(false);
                                setDisableBotonEnvio(false);
                                setnumeroAPI(7);
                                setnomColor("primary");
                            }}> Cerrar
                            <CloseCircleOutlined />
                        </Button>
                    </Modal.Footer>

                </Modal>

                {/* MODAL REZAGO OF INGRESADA */}

                <Modal
                    show={show2}
                    size="lg"
                    onHide={handleClose2}
                    backdrop="static"
                    keyboard={false}
                    name="loading"
                    className="text-center justify-content-center shadow">

                    <Modal.Header style={{ background: colorCardHeader }}>
                        <Modal.Title className="container text-center justify-content-center" style={{ color: Color_Verde }}>
                            <FormOutlined />{" "}
                            ORDEN DE FLETE INGRESADA CON REZAGO {" "} </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Container

                            className=" rounded mt-3 pt-3 pb-3 w-40 shadow"
                            style={{ background: Color_Blanco }}
                        >


                            <Row >
                                <Col>
                                    <h6 style={{ color: Color_Verde }}>
                                        <b> ESTADO REZAGO ORDEN FLETE INGRESADA </b>
                                    </h6>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-3 mt-2 d-flex justify-content-end">
                                    <label style={{ color: Color_Verde }} ><b>
                                        OF Rezago
                                    </b></label>
                                </Col>
                                <Col className="mt-2">
                                    <input size={40} disabled
                                        value={OrdenFleteRezago2}
                                    ></input>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3 mt-2 d-flex justify-content-end">
                                    <label style={{ color: Color_Verde }} ><b>
                                        OF Original
                                    </b></label>
                                </Col>
                                <Col className="mt-2">
                                    <input size={40} disabled
                                        value={OrdenFleteOriginal2}
                                    ></input>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3 mt-2 d-flex justify-content-end">
                                    <label style={{ color: Color_Verde }} ><b>
                                        Dias
                                    </b></label>
                                </Col>
                                <Col className="mt-2">
                                    <input size={40} disabled
                                        value={DiasRezago2}
                                    ></input>
                                </Col>
                            </Row>

                            <hr style={{ color: Color_Verde }} />

                            <Row className="mt-2">
                                <label style={{ color: Color_Verde }}> <b>
                                    Motivo
                                </b> </label>
                            </Row>

                            <Row>
                                <Container >
                                    <InputGroup>
                                        <Form.Control
                                            as="textarea"
                                            className="mt-1"
                                            value={MotivoRezago2}
                                            disabled
                                            style={{ color: Color_Negro, resize: 'none', height: 120 }}

                                        />

                                    </InputGroup>
                                    <Col className="mt-1 d-flex justify-content-end">
                                        <label style={{ color: Color_Gris }}> {LargoMotivo2}</label>
                                    </Col>
                                </Container>
                            </Row>

                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button danger type="primary" onClick={() => { handleClose1(); setShowU(false) }}> Cerrar
                            <CloseCircleOutlined />
                        </Button>

                        <Button type="primary" style={{ color: Color_Blanco, backgroundColor: Color_Verde }} onClick={() => { setShowU(true); OpenForm(modalInfoDto) }}> Crear Ticket Para Recuperar Bulto
                            <RightSquareFilled />
                        </Button>

                    </Modal.Footer>

                </Modal>

            </>

        </ConfigProvider>
    )
}